import {
    CanvasState,
    VideoItem,
    VideoItemAnimation,
    VideoSettings,
    VideoTrack,
} from "./lib/models";
import {
    onAddAnimationToVideoItem,
    onPlaceHorizontal,
    onPlaceVertical,
} from "./lib/video-util";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "./components/ui/button";
import { hexToRGB, secondsToTime } from "./lib/utils";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";
import { useToast } from "./components/ui/use-toast";

import { Dialog, DialogContent } from "./components/ui/dialog";
import { twMerge } from "tailwind-merge";

import getDummyData, { createDummyTextData } from "./lib/dummy-data";
import {
    createVideoItemImage,
    createVideoItemText,
    createVideoItemTransition,
} from "./lib/videoitemcreate";
import {
    ImageIcon,
    BoldIcon,
    AlignStartVerticalIcon,
    AlignCenterVerticalIcon,
    AlignEndVerticalIcon,
    AlignStartHorizontalIcon,
    AlignCenterHorizontalIcon,
    AlignEndHorizontalIcon,
    DownloadCloudIcon,
    LaptopIcon,
    Square,
    CaseSensitiveIcon,
    Music,
    LayoutPanelTopIcon,
    DownloadIcon,
    TypeIcon,
} from "lucide-react";
import VideoCanvas from "./components/video/VideoCanvas";
import ANIMATIONS, { ANIMATION_TEMPLATES } from "./lib/animations.constant";

import { InputSidebar } from "./components/ui/sidebar/input";
import {
    SelectContentSidebar,
    SelectItemSidebar,
    SelectSidebar,
    SelectTriggerSidebar,
    SelectValueSidebar,
} from "./components/ui/sidebar/select";
import FONTS from "./lib/fonts.constant";

import { Timeline } from "./components/video/Timeline";
import { ButtonSidebar } from "./components/ui/sidebar/button";
import { Input } from "./components/ui/input";
import { Label } from "./components/ui/label";
import { SelectContent, SelectItem, SelectValue } from "./components/ui/select";

import { HeaderImage } from "./components/video/Header/HeaderImage";
import { HeaderItemBackgroundColor } from "./components/video/Header/HeaderItemBackgroundColor";

import { HeaderAlignAllElements } from "./components/video/Header/HeaderAlignAllElements";
import { HeaderBackground } from "./components/video/Header/HeaderBackground";
import MoreInfo from "./components/ui/more-info";
import { MusicDialog } from "./components/music/MusicDialog";
import { AUDIO_TRACKS } from "./lib/audiotracks.constant";
import { usePostHog } from "posthog-js/react";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "./components/ui/popover";
import useScreenSize from "./components/hooks/useScreenSize";
import { AIDialog } from "./components/ai/dialog";

export default function App() {
    const posthog = usePostHog();
    const [openAI, setOpenAI] = useState(true);

    const { isMobile } = useScreenSize();

    const { toast } = useToast();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const ffmpegRef = useRef(new FFmpeg());
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const focusLineRef = useRef<HTMLSpanElement | null>(null);
    const timelineRef = useRef<HTMLDivElement | null>(null);
    const timelineContainer = useRef<HTMLDivElement | null>(null);
    const timelineDotRef = useRef<HTMLDivElement | null>(null);

    const [onDragTimeline, setOnDragTimeline] = useState(false);
    const [renderingVideoMsg, setRenderingVideoMsg] = useState("");
    const [videoSettings, setVideoSettings] = useState<VideoSettings>({
        resolution: "1920x1080",
        width: 1920,
        height: 1080,
        framerate: 30,
        duration: 5,
        audioTrack: AUDIO_TRACKS[0],
    });
    const [isFFMPEGLoaded, setFFMPEGLoaded] = useState(false);
    const [videoTracks, setVideoTracks] = useState<VideoTrack[]>([]);
    const [toggleExportDialog, onToggleExportDialog] = useState<boolean>(false);
    const [canvasState, setCanvasState] = useState<CanvasState>({
        rendering: false,
        playing: false,
        runnableTimer: 0,
        recordedChunks: [] as Blob[],
        runnableTimerMax: videoSettings.duration * videoSettings.framerate,
        runnableTimerStart: 0,
        runnableImgCached: [] as HTMLImageElement[],
    });
    const [userEmail, setUserEmail] = useState("none");

    const [isMusicDialog, setMusicDialog] = useState<boolean>(false);

    const [canvasSettings, setCanvasSettings] = useState({
        scale: "0.4",
    });
    const [videoTrackIndexSelected, setVideoTrackIndexSelected] =
        useState<number>(-1);

    const [videoDownloadURL, setVideoDownloadURL] = useState("");

    useEffect(() => {
        setRenderingVideoMsg("");
    }, [videoDownloadURL]);

    const onExport = async () => {
        if (userEmail !== "none") {
            posthog?.identify("distinct_id", { email: userEmail });
            fetch(
                "https://europe-west1-contentai-371710.cloudfunctions.net/quickeditloops",
                {
                    method: "POST",
                    body: JSON.stringify({
                        email: userEmail,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
        }

        posthog?.capture("on_export", {
            duration: videoSettings.duration,
            numberOfFrames: videoSettings.framerate * videoSettings.duration,
            frames: videoTracks.map((e) => e.value).join(", "),
        });

        setVideoDownloadURL("");
        setRenderingVideoMsg(
            "Setting up your render. Please keep this tab open until it's done."
        );
        const onLoadFFMPEG = async () => {
            setFFMPEGLoaded(true);
            const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd";
            const ffmpeg = ffmpegRef.current;

            const coreResponse = await fetch(`${baseURL}/ffmpeg-core.js`);
            const wasmResponse = await fetch(`${baseURL}/ffmpeg-core.wasm`);

            const coreBlob = await coreResponse.blob();
            const wasmBlob = await wasmResponse.blob();

            const coreURL = URL.createObjectURL(coreBlob);

            const wasmURL = URL.createObjectURL(wasmBlob);

            await ffmpeg.load({
                coreURL,
                wasmURL,
            });

            // await ffmpeg.load({
            //     coreURL: await toBlobURL(
            //         `${baseURL}/ffmpeg-core.js`,
            //         "text/javascript"
            //     ),
            //     wasmURL: await toBlobURL(
            //         `${baseURL}/ffmpeg-core.wasm`,
            //         "application/wasm"
            //     ),
            // });
        };
        if (!isFFMPEGLoaded) {
            onLoadFFMPEG();
        }
        setRenderingVideoMsg("Starting your render");
        setCanvasState({
            ...canvasState,
            rendering: false,
            recordedChunks: [], //Reset previous
            playing: false,
            runnableTimerStart: 0,
            runnableTimerMax: videoSettings.duration * videoSettings.framerate,
            runnableTimer: 0,
        });
        onUpdateAllIndividualFramesPerVideoTrack(() => {
            setTimeout(() => {
                setRenderingVideoMsg("Rendering frame by frame");
                setCanvasState({
                    ...canvasState,
                    rendering: true,
                    recordedChunks: [], //Reset previous
                    playing: false,
                    runnableTimerStart: 0,
                    runnableTimerMax:
                        videoSettings.duration * videoSettings.framerate,
                    runnableTimer: 0,
                });
            }, 3000);
        });
    };

    const onUpdateAllIndividualFramesPerVideoTrack = useCallback(
        async (callback?: any) => {
            if (videoTracks.length === 0) {
                return console.log("no video tracks");
            }

            const loadImage = (val: string): Promise<HTMLImageElement> =>
                new Promise<HTMLImageElement>((resolve, reject) => {
                    const img = new Image();
                    img.onload = function () {
                        resolve(img);
                    };
                    img.src = val;
                });

            //On add individual frames!
            let _videoTracks = [...videoTracks];

            videoTracks.map(async (videoTrack: VideoTrack) => {
                if (videoTrack.name === "image" && !videoTrack.imgCache) {
                    videoTrack.imgCache = await loadImage(
                        videoTrack.value || ""
                    );
                }

                videoTrack = await onAddAnimationToVideoItem(
                    videoSettings,
                    videoTrack,
                    videoTrack.animations || []
                );

                return videoTrack;
            });
            setVideoTracks(_videoTracks);
            if (callback) {
                callback();
            }
        },
        [videoTracks, videoSettings]
    );

    const onSelectAudioCurrentTime = (runnableTimer: number) => {
        if (audioRef.current) {
            const selectedSeconds = Math.floor(
                runnableTimer / videoSettings.framerate
            );
            audioRef.current.currentTime = selectedSeconds;
        }
    };

    const onPlay = useCallback(() => {
        onUpdateAllIndividualFramesPerVideoTrack();
        const runnableTimerReset =
            canvasState.runnableTimer ===
            videoSettings.duration * videoSettings.framerate - 1
                ? 0
                : canvasState.runnableTimer;
        onSelectAudioCurrentTime(runnableTimerReset);
        if (!canvasState.rendering && !canvasState.playing) {
            if (audioRef.current) {
                audioRef.current.play();
            }
            setCanvasState({
                ...canvasState,
                playing: true,
                runnableTimer: runnableTimerReset,
                runnableTimerStart: 0,
                runnableTimerMax:
                    videoSettings.duration * videoSettings.framerate,
            });
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
            }
            setCanvasState({
                ...canvasState,
                playing: false,
                rendering: false,
                runnableTimer: runnableTimerReset,
            });
        }
    }, [canvasState, videoSettings, onUpdateAllIndividualFramesPerVideoTrack]);

    useEffect(() => {
        if (canvasRef.current && videoTracks.length === 0) {
            const _videoTracks: VideoTrack[] = [];

            let animationTemplate = ANIMATION_TEMPLATES[0];
            let animationTemplateIndex = 0;

            _videoTracks.push(
                createDummyTextData(
                    videoSettings,
                    "text",
                    0,
                    60,
                    "We are hiring",
                    [],
                    { fontSize: 200 }
                )
            );
            _videoTracks.push(
                createDummyTextData(
                    videoSettings,
                    "text",
                    61,
                    121,
                    "a software engineer!",
                    [],
                    { fontSize: 200 }
                )
            );

            _videoTracks.push(
                createDummyTextData(
                    videoSettings,
                    "text",
                    122,
                    242,
                    "Visit QuickEdit.ai 🚀",
                    [],
                    { fontSize: 200 }
                )
            );

            _videoTracks.push(
                getDummyData(
                    videoSettings,
                    "background",
                    0,
                    videoSettings.duration * videoSettings.framerate,
                    "#131317",
                    "#131317",
                    "#131317",
                    []
                )
            );

            _videoTracks.map(async (videoTrack: VideoTrack, index: number) => {
                if (videoTrack.name !== "background") {
                    animationTemplateIndex =
                        index % animationTemplate.animations.length;

                    const animations: VideoItemAnimation[] = [];

                    const firstAnimations =
                        animationTemplate.animations[animationTemplateIndex]
                            .firstFrames;
                    const lastAnimations =
                        animationTemplate.animations[animationTemplateIndex]
                            .lastFrames;

                    for (let i = 0; i < firstAnimations.length; i++) {
                        let firstAnimation = ANIMATIONS.find(
                            (e) => e.animationKey === firstAnimations[i]
                        );

                        if (firstAnimation !== undefined) {
                            animations.push(firstAnimation);
                        }
                    }

                    for (let i = 0; i < lastAnimations.length; i++) {
                        const lastAnimation = ANIMATIONS.find(
                            (e) => e.animationKey === lastAnimations[i]
                        );
                        if (lastAnimation !== undefined) {
                            animations.push(lastAnimation);
                        }
                    }

                    videoTrack.animations = animations;
                }
                videoTrack = await onAddAnimationToVideoItem(
                    videoSettings,
                    videoTrack
                );
                return videoTrack;
            });

            setVideoTracks(_videoTracks);
        }
    }, [canvasRef, canvasState, videoTracks, videoSettings]);

    const onClearVideoTracks = () => {
        // Clear the existing videoTracks
        setVideoTracks([
            getDummyData(
                videoSettings,
                "background",
                0,
                videoSettings.duration * videoSettings.framerate,
                "#131317"
            ),
        ]);
    };

    //Runnable!
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        //Export it!
        const handleExport = async (recordedChunks: Blob[]) => {
            try {
                const ffmpeg = ffmpegRef.current;
                setRenderingVideoMsg("Bundling frames into a video file");
                for (let i = 0; i < recordedChunks.length; i++) {
                    const blob = recordedChunks[i];
                    const url = URL.createObjectURL(blob);
                    const name = `image${i.toString().padStart(1, "0")}.png`; // Ensure the name matches the pattern image%01d.jpg
                    await ffmpeg.writeFile(name, await fetchFile(url));
                    URL.revokeObjectURL(url); // Clean up the object URL
                }

                ffmpeg.on("progress", ({ progress, time }) => {
                    setRenderingVideoMsg(
                        `Rendering to mp4 ${Math.floor(progress * 100)}%`
                    );
                });

                if (videoSettings.audioTrack.url === "silence.mp3") {
                    await ffmpeg.exec([
                        "-framerate",
                        "" + videoSettings.framerate,
                        "-i",
                        "image%01d.png",
                        "-c:v",
                        "libx264",
                        "-pix_fmt",
                        "yuv420p",
                        "tommyjepsen.mp4",
                    ]);
                } else {
                    const audioFile = await fetchFile(
                        window.location.href + videoSettings.audioTrack.url
                    );
                    const audioFileBlob = await new Blob([audioFile.buffer], {
                        type: "audio/mp3",
                    });
                    const audioUrl = URL.createObjectURL(audioFileBlob);
                    await ffmpeg.writeFile(
                        "track.mp3",
                        await fetchFile(audioUrl)
                    );
                    await ffmpeg.exec([
                        "-framerate",
                        "" + videoSettings.framerate,
                        "-i",
                        "image%01d.png",
                        "-i",
                        "track.mp3",
                        "-c:a",
                        "copy",
                        "-shortest",
                        "-c:v",
                        "libx264",
                        "-pix_fmt",
                        "yuv420p",
                        "tommyjepsen.mp4",
                    ]);
                }

                const data: any = await ffmpeg.readFile("tommyjepsen.mp4");
                const videoBlob = await new Blob([data.buffer], {
                    type: "video/mp4",
                });
                const videoUrl = URL.createObjectURL(videoBlob);

                //Create gif from mp4
                // await ffmpeg.exec(["-i", "tommyjepsen.mp4", "output.gif"]);

                // const dataGif: any = await ffmpeg.readFile("output.gif");
                // const videoBlobGif = await new Blob([dataGif.buffer], {
                //     type: "image/gif",
                // });
                // const videoUrlGif = URL.createObjectURL(videoBlobGif);
                // console.log("videoUrlGif",videoUrlGif);

                // //Download videoUrlGif automatically
                // const link = document.createElement("a");
                // link.href = videoUrlGif;
                // link.download = "output.gif";
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);

                if (videoRef.current) {
                    videoRef.current.src = videoUrl;
                }
                setVideoDownloadURL(videoUrl);
                toast({
                    title: "Rendering done",
                    description: "You can now download your MP4 file",
                });
                setCanvasState({
                    ...canvasState,
                    rendering: false,
                });
            } catch (e) {
                console.error(e);
            }
        };

        //Loop runnable
        const loop = async (blob: Blob | null) => {
            return (timeoutId = setTimeout(
                () => {
                    if (!canvasState.playing && !canvasState.rendering) {
                        clearTimeout(timeoutId);
                        return;
                    }

                    const rc = blob
                        ? [...canvasState.recordedChunks, blob]
                        : canvasState.recordedChunks;

                    if (
                        (canvasState.playing || canvasState.rendering) &&
                        canvasState.runnableTimer >=
                            canvasState.runnableTimerStart &&
                        canvasState.runnableTimer < canvasState.runnableTimerMax
                    ) {
                        setCanvasState({
                            ...canvasState,
                            runnableTimer: canvasState.runnableTimer + 1,
                            recordedChunks: rc,
                        });
                    } else {
                        if (canvasState.rendering) {
                            setCanvasState({
                                ...canvasState,
                                playing: false,
                                rendering: false,
                                recordedChunks: rc,
                                runnableTimer: canvasState.runnableTimerStart,
                            });
                            handleExport(rc);
                        } else {
                            //Start over playing it!
                            if (audioRef.current) {
                                audioRef.current.currentTime = 0;
                            }
                            setCanvasState({
                                ...canvasState,
                                runnableTimer: canvasState.runnableTimerStart,
                            });
                        }
                    }
                },
                canvasState.rendering ? 0 : 1000 / videoSettings.framerate //Run the video at this frameRate or as fast as possible when rendering!
            ));
        };

        //Draw on canvas
        const runnable = async () => {
            // console.log(
            //     `RunnableTimer: ${canvasState.runnableTimer} - Rendering: ${canvasState.rendering} - Playing: ${canvasState.playing}`
            // );

            const canvas: any = canvasRef.current;
            if (canvas == null) {
                return setTimeout(() => {
                    runnable();
                }, 1000);
            }

            const context: CanvasRenderingContext2D = canvas.getContext("2d");
            context.imageSmoothingEnabled = false;

            if (timelineDotRef && timelineDotRef.current) {
                const maxRunnableTimer =
                    videoSettings.duration * videoSettings.framerate;
                const timelineDotRefXPosition =
                    timelineRef!.current!.clientWidth *
                    (canvasState.runnableTimer / maxRunnableTimer);

                timelineDotRef!.current!.style.left = `${timelineDotRefXPosition}px`;
            }

            context.clearRect(0, 0, canvas.width, canvas.height);

            //Destruct videoTracks items into a big array of videoitems
            const _videoItems: VideoItem[] = [];
            videoTracks.forEach((track: VideoTrack, index: number) => {
                track.items.forEach((item: VideoItem) => {
                    const _item: VideoItem = { ...item, parentIndex: index };
                    _videoItems.push(_item);
                });
            });

            //Find items with frame matching runnableTimer
            const items = _videoItems.filter(
                (item: any) => item.frame === canvasState.runnableTimer
            );
            //Opposite order to make sure the top layer is rendered last
            items.reverse();

            //Loop through items and set a promise to resolve when done fillText
            const promises = items.map((item: VideoItem) => {
                return new Promise((res, rej) => {
                    if (item.backgroundColor) {
                        context.fillStyle = item.backgroundColor;
                        context.fillRect(0, 0, canvas.width, canvas.height);
                    }
                    if (item.name === "text") {
                        const fontSize =
                            (item.fontSize || 100) * (item.scale || 1);

                        context.fillStyle = `rgba(${hexToRGB(
                            item.fontColor || "#cccccc"
                        )}, ${item.opacity})`;
                        context.font = `${
                            item.fontWeight || "bold"
                        } ${fontSize}px ${item.fontFamily}`;
                        context.textBaseline = item.textBaseline
                            ? item.textBaseline
                            : "middle";
                        context.textAlign = item.textAlign
                            ? item.textAlign
                            : "center";

                        const itemValueSplit = item.value.split("\n");
                        itemValueSplit.forEach((itemValueSplitItem, index) => {
                            context.fillText(
                                itemValueSplitItem,
                                item.x || 0,
                                (item.y || 0) + index * (item.fontSize || 0)
                            );
                        });
                    }
                    if (item.name === "rectangle") {
                        context.fillStyle = item.value;
                        context.fillRect(
                            item.x || 0,
                            item.y || 0,
                            item.width,
                            item.height
                        );
                    }
                    if (item.name === "background") {
                        context.fillStyle = item.value;
                        context.fillRect(0, 0, canvas.width, canvas.height);
                    }
                    if (item.name === "image") {
                        let img: HTMLImageElement;

                        //Scale image based on item.scale
                        let width = item.width;
                        let height = item.height;
                        if (item.scale) {
                            width = item.width * item.scale;
                            height = item.height * item.scale;
                        }

                        if (
                            item.parentIndex &&
                            videoTracks[item.parentIndex].imgCache !== undefined
                        ) {
                            img = videoTracks[item.parentIndex]
                                .imgCache as HTMLImageElement;
                            img.crossOrigin = "anonymous"; // This enables CORS
                        } else {
                            img = new Image();
                            img.src = item.value || "";
                            img.crossOrigin = "anonymous"; // This enables CORS
                            img.onload = function () {};
                        }
                        context.globalAlpha = item.opacity || 1;
                        context.drawImage(
                            img,
                            (item.x || 0) * (item.scale || 1),
                            (item.y || 0) * (item.scale || 1),
                            width,
                            height
                        );
                        context.globalAlpha = 1;
                    }
                    res("done");
                });
            });

            await Promise.all(promises);

            if (focusLineRef && focusLineRef.current) {
                focusLineRef.current.scrollIntoView({ block: "center" });
            }

            if (canvasState.rendering) {
                const blob: Blob | null = await new Promise((res, rej) => {
                    const _canvas: HTMLCanvasElement | null = canvasRef.current;
                    if (_canvas !== undefined && _canvas != null) {
                        _canvas.toBlob(
                            (blob: Blob | null) => {
                                res(blob);
                            },
                            "image/png",
                            "1"
                        );
                    }
                });
                if (blob != null) {
                    await loop(blob);
                }
            } else if (canvasState.playing) {
                await loop(null);
            }
        };

        runnable();
        return () => {
            clearTimeout(timeoutId); // Clear the timeout when the component unmounts or dependencies change
        };
    }, [
        canvasRef,
        videoTracks,
        videoSettings,
        canvasState.playing,
        canvasState.recordedChunks,
        canvasState.rendering,
        canvasState.runnableTimer,
        canvasState,
        toast,
        setCanvasState,
    ]);

    const onSelectFrameToPlayFrom = (
        runnableTimer: number,
        shouldPlay?: boolean,
        runnableTimerStart?: number,
        runnableTimerMax?: number
    ) => {
        onUpdateAllIndividualFramesPerVideoTrack(() => {
            onSelectAudioCurrentTime(runnableTimer);
            if (shouldPlay) {
                if (runnableTimerMax && runnableTimerStart) {
                    setCanvasState({
                        ...canvasState,
                        playing: true,
                        runnableTimer: runnableTimer,
                        runnableTimerStart: runnableTimerStart,
                        runnableTimerMax: runnableTimerMax,
                    });
                } else {
                    setCanvasState({
                        ...canvasState,
                        playing: true,
                        runnableTimer: runnableTimer,
                    });
                }
            } else {
                setCanvasState({
                    ...canvasState,
                    runnableTimer: runnableTimer,
                });
            }
        });
    };

    //Change fps, video resolution etc. then update frames per track
    useEffect(() => {
        onUpdateAllIndividualFramesPerVideoTrack();
    }, [videoSettings]);

    useEffect(() => {
        //Only do it if any input or textarea is not in focus
        const handleKeyDown = (e: any) => {
            if (e.code === "Space") {
                if (
                    document.activeElement?.tagName === "BODY" ||
                    document.activeElement?.tagName === "DIV"
                ) {
                    onPlay();
                }
            }
            if (e.code === "Escape") {
                setVideoTrackIndexSelected(-1);
            }
        };

        //Add event listener that if you hold cmd down and scroll up it zooms out and on windows ctrl
        const handleWheel = (e: any) => {
            if (e.metaKey || e.ctrlKey) {
                if (e.deltaY < 0) {
                    if (canvasSettings.scale === "0.1") return;
                    setCanvasSettings({
                        ...canvasSettings,
                        scale:
                            "" +
                            (Number(canvasSettings.scale) - 0.1).toFixed(1),
                    });
                } else if (e.deltaY > 0) {
                    if (canvasSettings.scale === "10") return;
                    setCanvasSettings({
                        ...canvasSettings,
                        scale:
                            "" +
                            (Number(canvasSettings.scale) + 0.1).toFixed(1),
                    });
                }
            }
        };
        window.addEventListener("wheel", handleWheel);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("wheel", handleWheel);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [canvasState.playing, canvasSettings.scale, onPlay]);

    const onChangeFontFamilyForAllVideoTracks = (fontFamily: string) => {
        const _videoTracks = [...videoTracks];
        _videoTracks.map((videoTrack: VideoTrack) => {
            if (videoTrack.name === "text") {
                videoTrack.fontFamily = fontFamily;
                videoTrack = onAddAnimationToVideoItem(
                    videoSettings,
                    videoTrack,
                    videoTrack.animations || []
                );
            }

            return videoTrack;
        });
        setVideoTracks(_videoTracks);
    };

    const onAddNewItemBelow = (index: number, type: string) => {
        let _videoTracks = [...videoTracks];

        _videoTracks.splice(
            index + 1,
            0,
            type === "text"
                ? createVideoItemText(
                      {
                          frameLength: 60,
                          placementHorizontal: "center",
                          placementVertical: "center",
                      },
                      videoSettings
                  )
                : type === "image"
                ? createVideoItemImage({
                      frameLength: 90,
                      placementHorizontal: "center",
                      placementVertical: "center",
                  })
                : createVideoItemTransition({
                      frameLength: 30,
                      placementHorizontal: "center",
                      placementVertical: "center",
                  })
        );

        _videoTracks =
            onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack(
                _videoTracks
            );

        onSetTotalTimeFromVideoTracks(_videoTracks);

        setVideoTracks(_videoTracks);

        setTimeout(() => {
            setVideoTrackIndexSelected(index + 1);
        }, 0);
    };

    const onSetTotalTimeFromVideoTracks = (_videoTracks: VideoTrack[]) => {
        let countFrames = 0;
        _videoTracks.map((videoTrack: VideoTrack, index: number) => {
            if (videoTrack.name !== "background") {
                if (videoTrack.frameLengthDriven) {
                    countFrames = countFrames + videoTrack.frameLength;
                } else {
                    countFrames =
                        countFrames +
                        (videoTrack.lastFrame - videoTrack.firstFrame);
                }
            }
            return videoTrack;
        });

        setVideoSettings({
            ...videoSettings,
            duration: countFrames / videoSettings.framerate,
        });

        return countFrames;
    };

    const onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack = (
        _videoTracks: VideoTrack[]
    ): VideoTrack[] => {
        let __videoTracks = [..._videoTracks];
        let countFrames = 0;
        __videoTracks.map((videoTrack: VideoTrack) => {
            if (videoTrack.frameLengthDriven) {
                videoTrack.firstFrame = countFrames;
                videoTrack.lastFrame = countFrames + videoTrack.frameLength;
            }
            countFrames = countFrames + videoTrack.frameLength + 1;
            videoTrack = onAddAnimationToVideoItem(videoSettings, videoTrack);
            return videoTrack;
        });

        //set bg which is always first frame
        __videoTracks[__videoTracks.length - 1].firstFrame = 0;
        __videoTracks[__videoTracks.length - 1].lastFrame = countFrames;

        __videoTracks = updateAnimationFramesToVideoTrack(__videoTracks);

        return __videoTracks;
    };

    const updateAnimationFramesToVideoTrack = (_videoTracks: VideoTrack[]) => {
        let __videoTracks = [..._videoTracks];

        //Todo when you change frameLength on a videoTrack, you need to recalculate all animations on that videoTrack
        //So exit animations is 1.5 seconds and clip is 3 seconds, we need to figure out firstFrame and lastFrame on this
        __videoTracks.map((videoTrack: VideoTrack) => {
            videoTrack.animations?.map((animation: VideoItemAnimation) => {
                if (animation.animationAppend === "lastFrame") {
                    const oldFrameLengthInSeconds =
                        (animation.lastFrame || 0) -
                        (animation.firstFrame || 0);
                    animation.firstFrame =
                        videoTrack.lastFrame - oldFrameLengthInSeconds;
                    animation.lastFrame = videoTrack.lastFrame;
                }
                return animation;
            });
            return videoTrack;
        });

        return __videoTracks;
    };

    const onSetVideoTrackFrameLengthToSec = (index: number, sec: number) => {
        let _videoTracks = [...videoTracks];
        _videoTracks[index].frameLength = sec * videoSettings.framerate;
        _videoTracks[index].lastFrame =
            _videoTracks[index].firstFrame + _videoTracks[index].frameLength;

        _videoTracks =
            onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack(
                _videoTracks
            );

        onSetTotalTimeFromVideoTracks(_videoTracks);
        setVideoTracks(_videoTracks);
    };

    const onPlaceH = (xPosition: "center" | "start" | "end") => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];
        _videoTrack.x = onPlaceHorizontal(
            videoTracks[videoTrackIndexSelected],
            xPosition,
            videoSettings
        );
        _videoTrack.placementHorizontal = xPosition;
        _videoTrack = onAddAnimationToVideoItem(
            videoSettings,
            _videoTrack,
            _videoTrack.animations || []
        );
        setVideoTracks(_videoTracks);
    };

    const onPlaceV = (yPosition: "center" | "start" | "end") => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];
        _videoTrack.y = onPlaceVertical(
            videoTracks[videoTrackIndexSelected],
            yPosition,
            videoSettings
        );

        _videoTrack.placementVertical = yPosition;
        _videoTrack = onAddAnimationToVideoItem(
            videoSettings,
            _videoTrack,
            _videoTrack.animations || []
        );
        setVideoTracks(_videoTracks);
    };

    return (
        <div className="flex relative w-screen h-screen flex-row justify-center items-center text-zinc-900 bg-zinc-50 dark:bg-zinc-900 dark:text-zinc-50">
            <div className="fixed overflow-y-auto top-0 left-0 w-full flex items-center justify-between z-20 bg-zinc-900 border-b border-zinc-700 h-16 ">
                <div className="flex w-auto flex-row gap-1 ml-3">
                    <div className="justify-start mr-6 gap-4 w-[350px] flex items-center ">
                        <button
                            onClick={onPlay}
                            disabled={canvasState.rendering}
                            className={twMerge(
                                "h-10 w-[80px] relative border flex justify-center hover:opacity-80 items-center rounded-full ",
                                canvasState.rendering || canvasState.playing
                                    ? "bg-blue-700/50 border-blue-700/50 text-blue-100"
                                    : "bg-blue-700 border-blue-700  text-zinc-50"
                            )}
                        >
                            {canvasState.rendering || canvasState.playing ? (
                                <>
                                    <PauseIcon className="h-4 w-4 text-inherit" />{" "}
                                </>
                            ) : (
                                <>
                                    <PlayIcon className="h-4 w-4  text-zinc-50 " />{" "}
                                </>
                            )}
                        </button>
                        <div className="flex flex-row">
                            <p className="text-xs select-none text-zinc-400 font-mono">
                                {secondsToTime(
                                    Math.round(
                                        (canvasState.runnableTimer /
                                            (videoSettings.duration *
                                                videoSettings.framerate)) *
                                            videoSettings.duration
                                    )
                                )}
                            </p>
                        </div>
                        <div
                            className="h-10 flex justify-center items-center relative w-full "
                            onMouseMove={(e) => {
                                if (!onDragTimeline) {
                                    return;
                                }
                                const timelineContainerWidth =
                                    timelineContainer.current?.clientWidth || 0;
                                const interpolatedFramePerPixel =
                                    (videoSettings.duration *
                                        videoSettings.framerate) /
                                    timelineContainerWidth;

                                const x =
                                    e.clientX -
                                    (timelineContainer.current?.offsetLeft ||
                                        0);

                                const newTime = x * interpolatedFramePerPixel;
                                if (
                                    newTime <=
                                    videoSettings.duration *
                                        videoSettings.framerate
                                ) {
                                    onSelectFrameToPlayFrom(
                                        Math.round(newTime)
                                    );
                                }
                            }}
                            onMouseDown={(e) => {
                                setOnDragTimeline(true);
                            }}
                            onMouseUp={(e) => {
                                setOnDragTimeline(false);
                            }}
                            ref={timelineContainer}
                        >
                            <div
                                className="w-full h-4 bg-zinc-700 border-dotted rounded-lg relative"
                                ref={timelineRef}
                            ></div>
                            <div
                                ref={timelineDotRef}
                                className={twMerge(
                                    "h-6 left-[3px] rounded w-[6px] top-2 hover:bg-blue-500 bg-blue-500/50 absolute",
                                    onDragTimeline
                                        ? "cursor-grabbing"
                                        : "cursor-grab"
                                )}
                            ></div>
                        </div>
                        <div className="flex flex-row">
                            <p className="text-xs text-zinc-400  select-none font-mono">
                                {secondsToTime(
                                    Math.round(
                                        ((videoSettings.duration *
                                            videoSettings.framerate) /
                                            (videoSettings.duration *
                                                videoSettings.framerate)) *
                                            videoSettings.duration
                                    )
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center gap-4">
                        <div className="shrink-0">
                            <button
                                className=" bg-purple-800/40 text-purple-500 hover:opacity-80  font-semibold text-sm px-4 h-10 rounded-lg"
                                onClick={() => {
                                    setOpenAI(true);
                                }}
                            >
                                Use AI ✨
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex w-full gap-2 px-3 min-w-[350px] justify-end flex-row">
                    <div className="">
                        <button
                            className="w-[150px] text-ellipsis overflow-hidden  flex flex-row items-center bg-zinc-800/60 hover:bg-zinc-700 text-white text-sm px-4 h-10 rounded-lg"
                            onClick={() => {
                                setMusicDialog(true);
                            }}
                        >
                            <Music className="w-4 h-4 text-zinc-400 mr-2" />{" "}
                            <span className="shrink-0 text-sm text-ellipsis  font-semibold text-zinc-400">
                                {videoSettings.audioTrack.title === "No music"
                                    ? "Choose Music"
                                    : videoSettings.audioTrack.title}
                            </span>
                        </button>
                    </div>
                    <HeaderAlignAllElements
                        videoTracks={videoTracks}
                        setVideoTracks={setVideoTracks}
                        videoSettings={videoSettings}
                    />
                    <HeaderBackground
                        videoTracks={videoTracks}
                        setVideoTracks={setVideoTracks}
                    ></HeaderBackground>
                    <div className="">
                        <SelectSidebar
                            name="selectSizes"
                            onValueChange={(val) => {
                                const _videoSettings = {
                                    ...videoSettings,
                                };
                                if (val === "1920x1080") {
                                    _videoSettings.width = 1920;
                                    _videoSettings.height = 1080;
                                } else if (val === "1080x1920") {
                                    _videoSettings.width = 1080;
                                    _videoSettings.height = 1920;
                                } else if (val === "1920x1920") {
                                    _videoSettings.width = 1920;
                                    _videoSettings.height = 1920;
                                }
                                _videoSettings.resolution = val;
                                setVideoSettings(_videoSettings);
                            }}
                            value={videoSettings.resolution}
                        >
                            <SelectTriggerSidebar className="w-full text-left first-letter items-center">
                                <SelectValue placeholder="Video resolution" />
                            </SelectTriggerSidebar>
                            <SelectContent>
                                <SelectItem value="1920x1080">
                                    <div className="gap-2 flex flex-row items-center mr-2 text-sm ">
                                        <LaptopIcon className="h-3 w-3 text-zinc-400" />
                                        <p className="text-zinc-400 font-semibold text-sm">
                                            1920x1080
                                        </p>
                                    </div>
                                </SelectItem>
                                <SelectItem value="1080x1920">
                                    <div className="gap-2 flex flex-row items-center mr-2 text-sm ">
                                        <DevicePhoneMobileIcon className="h-3 w-3 text-zinc-400" />
                                        <p className="text-zinc-400 font-semibold text-sm">
                                            1080x1920
                                        </p>
                                    </div>
                                </SelectItem>
                                <SelectItem value="1920x1920">
                                    <div className="gap-2 flex flex-row items-center mr-2 text-sm">
                                        <Square className="h-3 w-3 text-zinc-400" />
                                        <p className="text-zinc-400 font-semibold text-sm">
                                            1920x1920
                                        </p>
                                    </div>
                                </SelectItem>
                            </SelectContent>
                        </SelectSidebar>
                    </div>
                    <button
                        className="flex flex-row gap-2 shrink-0 items-center bg-red-500/10 text-red-500 hover:opacity-80  font-semibold text-sm px-4 h-10 rounded-lg"
                        onClick={() => {
                            setVideoDownloadURL("");
                            setRenderingVideoMsg("");
                            onToggleExportDialog(true);
                            posthog?.capture("clicked_on_export");
                        }}
                    >
                        <DownloadIcon className="w-4 h-4" />
                        <span> Preview & Download</span>
                    </button>
                </div>
            </div>

            {/* Sidebar top */}
            {videoTrackIndexSelected !== -1 &&
                videoTracks[videoTrackIndexSelected] && (
                    <div className="fixed overflow-x-auto border-b z-10 left-[350px] max-w-[100vw-350px] right-0 flex flex-row h-16 p-3 gap-3 top-16 w-full border-zinc-700 bg-zinc-900">
                        <Popover>
                            <PopoverTrigger>
                                <ButtonSidebar>
                                    <LayoutPanelTopIcon className="w-3 h-3 shrink-0 mr-2" />
                                    <span className="text-xs text-zinc-200">
                                        Align
                                    </span>
                                </ButtonSidebar>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto">
                                <div className="grid grid-cols-3 gap-2">
                                    <ButtonSidebar
                                        className="shrink-0"
                                        onClick={() => onPlaceH("start")}
                                    >
                                        <AlignStartVerticalIcon className="w-3 h-3 shrink-0" />
                                    </ButtonSidebar>
                                    <ButtonSidebar
                                        className="shrink-0"
                                        onClick={() => onPlaceH("center")}
                                    >
                                        <AlignCenterVerticalIcon className="w-3 h-3 shrink-0" />
                                    </ButtonSidebar>
                                    <ButtonSidebar
                                        className="shrink-0"
                                        onClick={() => onPlaceH("end")}
                                    >
                                        <AlignEndVerticalIcon className="w-3 h-3 shrink-0" />
                                    </ButtonSidebar>
                                    <ButtonSidebar
                                        className="shrink-0"
                                        onClick={() => onPlaceV("start")}
                                    >
                                        <AlignStartHorizontalIcon className="w-3 h-3 shrink-0" />
                                    </ButtonSidebar>
                                    <ButtonSidebar
                                        className="shrink-0"
                                        onClick={() => onPlaceV("center")}
                                    >
                                        <AlignCenterHorizontalIcon className="w-3 h-3 shrink-0" />
                                    </ButtonSidebar>
                                    <ButtonSidebar
                                        className="shrink-0"
                                        onClick={() => onPlaceV("end")}
                                    >
                                        <AlignEndHorizontalIcon className="w-3 h-3 shrink-0" />
                                    </ButtonSidebar>
                                </div>
                            </PopoverContent>
                        </Popover>

                        {videoTracks[videoTrackIndexSelected].name ===
                            "text" && (
                            <>
                                <div className="flex justify-between">
                                    <SelectSidebar
                                        name="inputFontFamily"
                                        onValueChange={(val) => {
                                            const _videoTracks = [
                                                ...videoTracks,
                                            ];
                                            let _videoTrack =
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ];
                                            _videoTrack.fontFamily = val;
                                            _videoTrack =
                                                onAddAnimationToVideoItem(
                                                    videoSettings,
                                                    _videoTrack,
                                                    _videoTrack.animations || []
                                                );
                                            setVideoTracks(_videoTracks);
                                        }}
                                        value={
                                            videoTracks[videoTrackIndexSelected]
                                                ?.fontFamily
                                        }
                                    >
                                        <SelectTriggerSidebar
                                            icon={
                                                <BoldIcon className="w-3 h-3" />
                                            }
                                            className="w-full"
                                        >
                                            <SelectValueSidebar placeholder="Font family" />
                                        </SelectTriggerSidebar>
                                        <SelectContentSidebar>
                                            {FONTS.map((font) => (
                                                <SelectItemSidebar value={font}>
                                                    {font}
                                                </SelectItemSidebar>
                                            ))}
                                        </SelectContentSidebar>
                                    </SelectSidebar>
                                </div>
                                <div className="w-[100px]">
                                    <SelectSidebar
                                        name="inputFontWeight"
                                        onValueChange={(val) => {
                                            const _videoTracks = [
                                                ...videoTracks,
                                            ];
                                            let _videoTrack =
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ];
                                            _videoTrack.fontWeight = val as
                                                | "bold"
                                                | "normal";
                                            _videoTrack =
                                                onAddAnimationToVideoItem(
                                                    videoSettings,
                                                    _videoTrack,
                                                    _videoTrack.animations || []
                                                );
                                            setVideoTracks(_videoTracks);
                                        }}
                                        value={
                                            videoTracks[videoTrackIndexSelected]
                                                .fontWeight
                                        }
                                    >
                                        <SelectTriggerSidebar
                                            icon={
                                                <BoldIcon className="w-3 h-3" />
                                            }
                                            className="w-full"
                                        >
                                            <SelectValueSidebar placeholder="Weight" />
                                        </SelectTriggerSidebar>
                                        <SelectContentSidebar>
                                            <SelectItemSidebar value="normal">
                                                Normal
                                            </SelectItemSidebar>
                                            <SelectItemSidebar value="bold">
                                                Bold
                                            </SelectItemSidebar>
                                        </SelectContentSidebar>
                                    </SelectSidebar>
                                </div>
                                <div className="w-[100px]">
                                    <InputSidebar
                                        type="number"
                                        name="inputFontSize"
                                        icon={
                                            <CaseSensitiveIcon className="w-4 h-4" />
                                        }
                                        value={
                                            videoTracks[videoTrackIndexSelected]
                                                .fontSize
                                        }
                                        onChange={(e) => {
                                            const _videoTracks = [
                                                ...videoTracks,
                                            ];
                                            _videoTracks[
                                                videoTrackIndexSelected
                                            ].fontSize = +e.target.value;
                                            _videoTracks[
                                                videoTrackIndexSelected
                                            ] = onAddAnimationToVideoItem(
                                                videoSettings,
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ],
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ].animations || []
                                            );
                                            setVideoTracks(_videoTracks);
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row gap-1 ">
                                    <InputSidebar
                                        type="color"
                                        name="inputTextFill"
                                        className="w-14"
                                        value={
                                            videoTracks[videoTrackIndexSelected]
                                                .fontColor
                                        }
                                        onChange={(e) => {
                                            const _videoTracks = [
                                                ...videoTracks,
                                            ];
                                            _videoTracks[
                                                videoTrackIndexSelected
                                            ].fontColor = e.target.value;
                                            _videoTracks[
                                                videoTrackIndexSelected
                                            ].items.map((item: any) => {
                                                item.fontColor = e.target.value;
                                                return item;
                                            });
                                            setVideoTracks(_videoTracks);
                                        }}
                                    />
                                    <div className="flex-1">
                                        <InputSidebar
                                            type="text"
                                            className="flex-1 w-20 shrink-0"
                                            name="inputTextFill"
                                            value={
                                                videoTracks[
                                                    videoTrackIndexSelected
                                                ].fontColor
                                            }
                                            onChange={(e) => {
                                                const _videoTracks = [
                                                    ...videoTracks,
                                                ];
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ].fontColor = e.target.value;
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ].items.map((item: any) => {
                                                    item.fontColor =
                                                        e.target.value;
                                                    return item;
                                                });
                                                setVideoTracks(_videoTracks);
                                            }}
                                        />
                                    </div>
                                </div>
                                <HeaderItemBackgroundColor
                                    videoTracks={videoTracks}
                                    videoTrackIndexSelected={
                                        videoTrackIndexSelected
                                    }
                                    setVideoTracks={setVideoTracks}
                                />
                            </>
                        )}
                        {videoTracks[videoTrackIndexSelected].name ===
                            "image" && (
                            <>
                                <HeaderImage
                                    videoTracks={videoTracks}
                                    videoTrackIndexSelected={
                                        videoTrackIndexSelected
                                    }
                                    setVideoTracks={setVideoTracks}
                                    videoSettings={videoSettings}
                                    onSetVideoTrackFrameLengthToSec={
                                        onSetVideoTrackFrameLengthToSec
                                    }
                                />
                            </>
                        )}
                    </div>
                )}

            <AIDialog
                openAI={openAI}
                setOpenAI={setOpenAI}
                onSetTotalTimeFromVideoTracks={onSetTotalTimeFromVideoTracks}
                setVideoSettings={setVideoSettings}
                setVideoTracks={setVideoTracks}
                videoSettings={videoSettings}
                onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack={
                    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack
                }
                onClearVideoTracks={onClearVideoTracks}
            />
            <Dialog
                open={toggleExportDialog}
                onOpenChange={() => onToggleExportDialog(!toggleExportDialog)}
            >
                <DialogContent>
                    <div className="flex flex-col  gap-4 w-full items-end justify-end">
                        <div className="w-full flex justify-start items-start ">
                            {videoDownloadURL !== "" && (
                                <div className="flex w-full flex-col gap-4 items-center justify-center">
                                    <video
                                        ref={videoRef}
                                        src={videoDownloadURL}
                                        className="w-full h-auto"
                                        controls
                                    ></video>
                                    <Button
                                        onClick={() => {
                                            const a =
                                                document.createElement("a");
                                            a.href = videoDownloadURL;
                                            a.download = "video.mp4";
                                            a.click();
                                        }}
                                        variant="default"
                                        className="gap-2 flex flex-row"
                                    >
                                        <DownloadCloudIcon className="w-4 h-4" />
                                        <span>Download the video</span>
                                    </Button>
                                </div>
                            )}
                            {videoDownloadURL === "" && !renderingVideoMsg && (
                                <div className="flex flex-col gap-6">
                                    <div className="flex flex-col gap-4">
                                        <h1 className="dark:text-white font-semibold text-6xl tracking-tighter">
                                            Your video is almost ready
                                        </h1>
                                    </div>
                                    <div className="flex flex-col w-full gap-10 items-center justify-end">
                                        <p className="text-zinc-400">
                                            We just need to render it. Are you
                                            interested in a video editor that
                                            can do this, and much more? If so,
                                            drop your email below — we’ll notify
                                            you when it’s ready.
                                        </p>
                                        <div className="flex gap-4 flex-row items-end w-full">
                                            <div className="flex gap-2 flex-col  w-full">
                                                <Label>Enter your email</Label>
                                                <Input
                                                    onChange={(e) => {
                                                        setUserEmail(
                                                            e.target.value
                                                        );
                                                    }}
                                                    placeholder="Please enter your email"
                                                ></Input>
                                            </div>{" "}
                                            <Button
                                                onClick={onExport}
                                                className={twMerge(
                                                    "flex flex-row shrink-0",
                                                    renderingVideoMsg !== ""
                                                        ? "animate-pulse"
                                                        : ""
                                                )}
                                                disabled={
                                                    renderingVideoMsg !== ""
                                                }
                                                variant="default"
                                            >
                                                Render my video
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {videoDownloadURL === "" && renderingVideoMsg && (
                                <p className="text-white">
                                    <span>{renderingVideoMsg}</span>{" "}
                                    {canvasState.runnableTimer !== 0 && (
                                        <span className="font-bold uppercase tracking-tighter">
                                            {canvasState.runnableTimer}
                                            {""}/{""}
                                            {videoSettings.duration *
                                                videoSettings.framerate}
                                        </span>
                                    )}
                                </p>
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <MusicDialog
                isMusicDialog={isMusicDialog}
                setMusicDialog={setMusicDialog}
                videoSettings={videoSettings}
                setVideoSettings={setVideoSettings}
            />

            {isMobile && (
                <div className="w-screen h-screen bg-zinc-900/90 fixed z-50 left-0 top-0 right-0 bottom-0 flex justify-center items-center px-6">
                    <div className="p-6 bg-white rounded">
                        <p className="text-black text-center">
                            QuickEdit is only available on desktop for now.
                            Please log in via desktop to use QuickEdit ✌️
                        </p>
                    </div>
                </div>
            )}

            {/* Sidebar left */}
            <div className="fixed flex flex-col  p-3 border-r border-zinc-100 dark:border-zinc-700 overflow-y-auto left-0  top-[64px] max-w-[350px] max-h-[calc(100vh-64px)] min-h-[calc(100vh-64px-64p)] w-full h-full">
                <div className="flex flex-col pb-36">
                    <Timeline
                        onSetTotalTimeFromVideoTracks={
                            onSetTotalTimeFromVideoTracks
                        }
                        onSelectFrameToPlayFrom={onSelectFrameToPlayFrom}
                        setVideoSettings={setVideoSettings}
                        videoTracks={videoTracks}
                        videoTrackIndexSelected={videoTrackIndexSelected}
                        setVideoTrackIndexSelected={setVideoTrackIndexSelected}
                        setVideoTracks={setVideoTracks}
                        setCanvasState={setCanvasState}
                        canvasState={canvasState}
                        videoSettings={videoSettings}
                        onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack={
                            onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack
                        }
                        onSetVideoTrackFrameLengthToSec={
                            onSetVideoTrackFrameLengthToSec
                        }
                        onAddNewItemBelow={onAddNewItemBelow}
                    ></Timeline>

                    <div className="border-t gap-4 py-4 grid grid-cols-2 max-w-[450px] items-center justify-center left-0 w-full bg-zinc-900  border-zinc-700">
                        <button
                            tabIndex={-1}
                            className="w-full text-center rounded-lg border-zinc-700 hover:opacity-70 bg-zinc-700 border items-center h-10 hover:h-10 transition-all pr-4 flex justify-center "
                            onClick={() =>
                                onAddNewItemBelow(
                                    videoTracks.length - 2,
                                    "text"
                                )
                            }
                        >
                            <span className="flex-row flex justify-center items-center text-zinc-200 text-sm px-2 py-1 gap-2">
                                <TypeIcon className="h-4 w-4 stroke-1" />
                                <span>Add Text</span>
                            </span>
                        </button>
                        <button
                            tabIndex={-1}
                            className="w-full text-center rounded-lg border-zinc-700 hover:opacity-70 bg-zinc-700 border items-center h-10 transition-all pr-4 flex justify-center  "
                            onClick={() =>
                                onAddNewItemBelow(
                                    videoTracks.length - 2,
                                    "image"
                                )
                            }
                        >
                            <span className="flex-row flex justify-center items-center text-zinc-200 text-sm px-2 py-1 gap-2">
                                <ImageIcon className="h-4 w-4 stroke-1" />
                                <span>Add image</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Audio */}
            <audio
                ref={audioRef}
                src={"./" + videoSettings.audioTrack.url}
                preload="metadata"
            ></audio>

            {/* Canvas */}
            <div className="bg-zinc-200  dark:bg-zinc-900 fixed left-[350px] right-0 top-[64px] max-w-[calc(100vw-350px)] overflow-hidden  max-h-[calc(100vh-64px)]  w-full h-full flex flex-row">
                <div className="flex flex-1 h-full rounded-xl dark:bg-zinc-900 justify-center items-center w-full relative ">
                    <VideoCanvas
                        runnableTimer={canvasState.runnableTimer}
                        canvasRef={canvasRef}
                        videoSettings={videoSettings}
                        setVideoTracks={setVideoTracks}
                        canvasSettings={canvasSettings}
                        canvasState={canvasState}
                        videoTracks={videoTracks}
                        setVideoTrackIndexSelected={setVideoTrackIndexSelected}
                    />
                </div>
            </div>

            {/* Zoom! */}
            <div className="fixed right-4 bottom-4 z-20">
                <span className="text-sm font-semibold flex flex-row gap-3 items-center">
                    {/*<EmailButton />*/}
                    <button
                        onClick={() => {
                            if (canvasSettings.scale === "0.1") return;
                            setCanvasSettings({
                                ...canvasSettings,
                                scale:
                                    "" +
                                    (
                                        Number(canvasSettings.scale) - 0.1
                                    ).toFixed(1),
                            });
                        }}
                        className="p-4 hover:bg-zinc-700 rounded"
                    >
                        -
                    </button>
                    <button
                        onClick={() => {
                            if (canvasSettings.scale === "10") return;
                            setCanvasSettings({
                                ...canvasSettings,
                                scale:
                                    "" +
                                    (
                                        Number(canvasSettings.scale) + 0.1
                                    ).toFixed(1),
                            });
                        }}
                        className="p-4 hover:bg-zinc-700 rounded"
                    >
                        +
                    </button>
                    <span className="text-zinc-400 text-xs">
                        {Math.floor(+canvasSettings.scale * 100)}%
                    </span>
                    <span className="ml-2">
                        <MoreInfo />
                    </span>
                </span>
            </div>
        </div>
    );
}
