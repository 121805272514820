import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../../lib/utils";

const labelVariants = cva(
    "text-2xs uppercase font-bold text-zinc-600 dark:text-zinc-400 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
);

const LabelSidebar = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
        VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={cn(labelVariants(), className)}
        {...props}
    />
));
LabelSidebar.displayName = "LabelSidebar";

export { LabelSidebar };
