import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { EnvelopeIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { LinkedinIcon } from "lucide-react";

const MoreInfo = () => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <button>
                    <span className="bg-zinc-600 text-zinc-50 p-2 rounded-full px-3 font-bold">
                        ?
                    </span>
                </button>
            </PopoverTrigger>
            <PopoverContent className="mb-2 mr-2">
                <div className="flex flex-col gap-6 mb-6">
                    <div className="flex flex-row items-center gap-4">
                        <EnvelopeIcon className="w-4 h-4 text-zinc-50 stroke-1" />
                        <p className="text-sm font-semibold tracking-tight">
                            Any feedback?{" "}
                            <a
                                href="mailto:sam@samlafontaine.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="underline underline-offset-2"
                            >
                                Email us
                            </a>
                        </p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <SparklesIcon className="w-4 h-4 text-zinc-50 stroke-1" />
                        <p className="text-sm font-semibold tracking-tight">
                            Need inspiration?{" "}
                            <a
                                href="/examples"
                                className="underline underline-offset-2"
                            >
                                See examples
                            </a>
                        </p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <LinkedinIcon className="w-4 h-4 text-zinc-50 stroke-1" />
                        <p className="text-sm font-semibold tracking-tight">
                            Follow us on{" "}
                            <a
                                href="https://www.linkedin.com/company/quickeditai"
                                className="underline underline-offset-2"
                            >
                                LinkedIn
                            </a>
                        </p>
                    </div>
                </div>
                <div className="text-xs text-zinc-400 flex flex-row gap-1">
                    <p>Version 0.01</p>
                    <p>•</p>
                    <p>
                        Built by{" "}
                        <a
                            href="https://twitter.com/samlafontaine_"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline underline-offset-2"
                        >
                            Sam
                        </a>
                    </p>
                </div>
            </PopoverContent>
        </Popover>
    );
};

export default MoreInfo;
