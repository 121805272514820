import {
    AlignCenterHorizontalIcon,
    AlignCenterVerticalIcon,
    AlignEndHorizontalIcon,
    AlignEndVerticalIcon,
    AlignStartHorizontalIcon,
    AlignStartVerticalIcon,
    LayoutPanelTopIcon,
} from "lucide-react";
import { ButtonSidebar } from "../../ui/sidebar/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../ui/tooltip";
import {
    onAddAnimationToVideoItem,
    onPlaceHorizontal,
    onPlaceVertical,
} from "../../../lib/video-util";
import { VideoSettings, VideoTrack } from "../../../lib/models";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
interface HeaderAlignAllElementsProps {
    videoTracks: VideoTrack[];
    videoSettings: VideoSettings;
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
}
export const HeaderAlignAllElements = ({
    videoTracks,
    videoSettings,
    setVideoTracks,
}: HeaderAlignAllElementsProps) => {
    const onPlaceHAll = (xPosition: "center" | "start" | "end") => {
        const _videoTracks = [...videoTracks];
        _videoTracks.map((_videoTrack) => {
            _videoTrack.x = onPlaceHorizontal(
                _videoTrack,
                xPosition,
                videoSettings
            );
            _videoTrack.placementHorizontal = xPosition;
            _videoTrack = onAddAnimationToVideoItem(videoSettings, _videoTrack);
            return _videoTrack;
        });

        setVideoTracks(_videoTracks);
    };
    const onPlaceVAll = (yPosition: "center" | "start" | "end") => {
        const _videoTracks = [...videoTracks];
        _videoTracks.map((_videoTrack) => {
            _videoTrack.y = onPlaceVertical(
                _videoTrack,
                yPosition,
                videoSettings
            );

            _videoTrack.placementVertical = yPosition;
            _videoTrack = onAddAnimationToVideoItem(videoSettings, _videoTrack);
            return _videoTrack;
        });
        setVideoTracks(_videoTracks);
    };
    return (
        <Popover>
            <PopoverTrigger>
                <ButtonSidebar>
                    <LayoutPanelTopIcon className="w-3 h-3 shrink-0" />
                </ButtonSidebar>
            </PopoverTrigger>
            <PopoverContent className="w-auto">
                <div className="grid grid-cols-3 gap-2">
                    <TooltipProvider delayDuration={0}>
                        <Tooltip>
                            <TooltipTrigger>
                                <ButtonSidebar
                                    className="shrink-0"
                                    onClick={() => onPlaceHAll("start")}
                                >
                                    <AlignStartVerticalIcon className="w-3 h-3 shrink-0" />
                                </ButtonSidebar>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Align all horizontal left</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider delayDuration={0}>
                        <Tooltip>
                            <TooltipTrigger>
                                <ButtonSidebar
                                    className="shrink-0"
                                    onClick={() => onPlaceHAll("center")}
                                >
                                    <AlignCenterVerticalIcon className="w-3 h-3 shrink-0" />
                                </ButtonSidebar>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Align all horizontal center</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider delayDuration={0}>
                        <Tooltip>
                            <TooltipTrigger>
                                <ButtonSidebar
                                    className="shrink-0"
                                    onClick={() => onPlaceHAll("end")}
                                >
                                    <AlignEndVerticalIcon className="w-3 h-3 shrink-0" />
                                </ButtonSidebar>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Align all horizontal right</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                    <TooltipProvider delayDuration={0}>
                        <Tooltip>
                            <TooltipTrigger>
                                <ButtonSidebar
                                    className="shrink-0"
                                    onClick={() => onPlaceVAll("start")}
                                >
                                    <AlignStartHorizontalIcon className="w-3 h-3 shrink-0" />
                                </ButtonSidebar>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Align all vertically start</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                    <TooltipProvider delayDuration={0}>
                        <Tooltip>
                            <TooltipTrigger>
                                <ButtonSidebar
                                    className="shrink-0"
                                    onClick={() => onPlaceVAll("center")}
                                >
                                    <AlignCenterHorizontalIcon className="w-3 h-3 shrink-0" />
                                </ButtonSidebar>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Align all vertically center</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                    <TooltipProvider delayDuration={0}>
                        <Tooltip>
                            <TooltipTrigger>
                                <ButtonSidebar
                                    className="shrink-0"
                                    onClick={() => onPlaceVAll("end")}
                                >
                                    <AlignEndHorizontalIcon className="w-3 h-3 shrink-0" />
                                </ButtonSidebar>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Align all vertically right</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </PopoverContent>
        </Popover>
    );
};
