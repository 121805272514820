import { PaintBucketIcon } from "lucide-react";
import { VideoTrack } from "../../../lib/models";
import { InputSidebar } from "../../ui/sidebar/input";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../ui/tooltip";

interface HeaderBackgroundProps {
    videoTracks: VideoTrack[];
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
}

export const HeaderBackground = ({
    videoTracks,
    setVideoTracks,
}: HeaderBackgroundProps) => {
    return (
        <TooltipProvider delayDuration={0}>
            <Tooltip>
                <TooltipTrigger>
                    <div className="flex flex-row gap-1 items-center">
                        <InputSidebar
                            type="color"
                            name="inputBGFill"
                            className="w-11 p-2"
                            value={
                                videoTracks[videoTracks?.length - 1]
                                    ? videoTracks[videoTracks.length - 1].value
                                    : ""
                            }
                            onChange={(e) => {
                                const _videoTracks = [...videoTracks];
                                if (_videoTracks[_videoTracks.length - 1]) {
                                    _videoTracks[
                                        _videoTracks.length - 1
                                    ].value = e.target.value;
                                    _videoTracks[
                                        _videoTracks.length - 1
                                    ].items.map((item: any) => {
                                        item.value = e.target.value;
                                        return item;
                                    });
                                    setVideoTracks(_videoTracks);
                                }
                            }}
                        />
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Change main background color for entire video</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
