import * as React from "react";

import { cn } from "../../lib/utils";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    icon?: React.ReactNode;
    classNameContainer?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ classNameContainer, className, type, icon, ...props }, ref) => {
        return (
            <div className={cn("relative", classNameContainer)}>
                {icon && <div className="absolute left-3 top-3">{icon}</div>}
                <input
                    type={type}
                    className={cn(
                        "flex dark:text-zinc-50 h-10 w-full text-zinc-50 rounded-md border border-zinc-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-900 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-zinc-700 dark:bg-zinc-900 dark:ring-offset-zinc-200 dark:placeholder:text-zinc-400 dark:focus-visible:ring-zinc-300",
                        className,
                        icon ? "pl-9" : ""
                    )}
                    ref={ref}
                    {...props}
                />
            </div>
        );
    }
);
Input.displayName = "Input";

export { Input };
