import { VideoItemAnimation } from "./models";

const ANIMATIONS: VideoItemAnimation[] = [
    //Start

    {
        animationKey: "opacity-fade-in-first-frame",
        animationValue: "Opacity fade in",
        animationAppend: "firstFrame",
        animationEase: "ease-in",
    },
    {
        animationKey: "slide-in-down-first-frame",
        animationValue: "Slide in down",
        animationAppend: "firstFrame",
        animationEase: "ease-in",
    },
    {
        animationKey: "slide-in-up-first-frame",
        animationValue: "Slide in up",
        animationAppend: "firstFrame",
        animationEase: "ease-in",
    },
    {
        animationKey: "slide-in-left-first-frame",
        animationValue: "Slide in left",
        animationAppend: "firstFrame",
        animationEase: "ease-in",
    },
    {
        animationKey: "slide-in-right-first-frame",
        animationValue: "Slide in right",
        animationAppend: "firstFrame",
        animationEase: "ease-in",
    },
    {
        animationKey: "scale-up-first-frame",
        animationValue: "Scale up",
        animationAppend: "firstFrame",
        animationEase: "ease-in",
    },
    {
        animationKey: "scale-down-first-frame",
        animationValue: "Scale down",
        animationAppend: "firstFrame",
        animationEase: "ease-in",
    },
    {
        animationKey: "typewriter",
        animationValue: "Typewriter",
        animationAppend: "firstFrame",
        animationEase: "ease-out",
        firstValue: 0,
        lastValue: 1,
        animationRules: [
            {
                name: "Typewriter",
                value: 30,
            },
        ],
    },
    {
        animationKey: "words-fade-in-first-frame",
        animationValue: "Words fade in",
        animationAppend: "firstFrame",
        animationEase: "linear",
    },

    //Exit
    {
        animationKey: "opacity-fade-out-last-frame",
        animationValue: "Opacity fade out",
        animationAppend: "lastFrame",
        animationEase: "ease-out",
    },
    {
        animationKey: "slide-out-up-last-frame",
        animationValue: "Slide out up",
        animationAppend: "lastFrame",
        animationEase: "ease-out",
    },
    {
        animationKey: "slide-out-down-last-frame",
        animationValue: "Slide out down",
        animationAppend: "lastFrame",
        animationEase: "ease-out",
    },
    {
        animationKey: "slide-out-left-last-frame",
        animationValue: "Slide out left",
        animationAppend: "lastFrame",
        animationEase: "ease-out",
    },
    {
        animationKey: "slide-out-right-last-frame",
        animationValue: "Slide out right",
        animationAppend: "lastFrame",
        animationEase: "ease-out",
    },
    {
        animationKey: "scale-down-last-frame",
        animationValue: "Scale down",
        animationAppend: "lastFrame",
        animationEase: "ease-out",
    },
    {
        animationKey: "scale-up-last-frame",
        animationValue: "Scale up",
        animationAppend: "lastFrame",
        animationEase: "ease-out",
    },

    //Range
    {
        animationKey: "opacity",
        animationValue: "Opacity",
        animationAppend: "range",
        animationType: "range",
        animationEase: "ease-out",
        firstValue: 0,
        lastValue: 1,
    },
    {
        animationKey: "x",
        animationValue: "X Position",
        animationAppend: "range",
        animationType: "range",
        animationEase: "ease-out",
        firstValue: 0,
        lastValue: 100,
    },
    {
        animationKey: "y",
        animationValue: "Y Position",
        animationAppend: "range",
        animationType: "range",
        animationEase: "ease-out",
        firstValue: 0,
        lastValue: 100,
    },
    {
        animationKey: "scale",
        animationValue: "Scale",
        animationAppend: "range",
        animationType: "range",
        animationEase: "ease-out",
        firstValue: 0.9,
        lastValue: 1,
    },
];

export default ANIMATIONS;

type AnimationTemplateFrames = {
    firstFrames: string[];
    lastFrames: string[];
};

type AnimationTemplate = {
    style: string;
    animationDuration: number;
    animations: AnimationTemplateFrames[];
};

export const ANIMATION_TEMPLATES: AnimationTemplate[] = [
    {
        style: "fast",
        animationDuration: 1,
        animations: [
            {
                firstFrames: ["scale-down-first-frame"],
                lastFrames: [],
            },
            {
                firstFrames: ["scale-down-first-frame", "typewriter"],
                lastFrames: [],
            },
            {
                firstFrames: ["scale-down-first-frame"],
                lastFrames: [],
            },
            {
                firstFrames: ["scale-down-first-frame"],
                lastFrames: [],
            },
        ],
    },
    {
        style: "medium",
        animationDuration: 1.5,
        animations: [
            {
                firstFrames: ["scale-up-first-frame"],
                lastFrames: ["scale-down-last-frame"],
            },
            {
                firstFrames: ["scale-up-first-frame"],
                lastFrames: ["scale-down-last-frame"],
            },
        ],
    },
    {
        style: "slow",
        animationDuration: 2,
        animations: [
            {
                firstFrames: ["scale-up-first-frame"],
                lastFrames: ["scale-down-last-frame"],
            },
            {
                firstFrames: ["scale-up-first-frame"],
                lastFrames: ["scale-down-last-frame"],
            },
        ],
    },
];
