import ANIMATIONS from "./animations.constant";
import { VideoItemAnimation, VideoSettings, VideoTrack } from "./models";
import { getColor } from "./utils";
import { onPlaceHorizontal, onPlaceVertical } from "./video-util";

const onFindAndAddAnimationFromString = (animationString: string) => {
    let animations: VideoItemAnimation[] = [];
    const animationsSplitUp = animationString.split(" ") as [
        "opacity-fade-in-first-frame",
        "slide-in-down-first-frame",
        "opacity-fade-out-last-frame",
        "slide-out-up-last-frame"
    ];
    const _ANIMATIONS = [...ANIMATIONS];
    //Find the animation in the ANIMATIONS constant
    for (let i = 0; i < animationsSplitUp.length; i++) {
        const animationSplitUp = animationsSplitUp[i];
        const animation = _ANIMATIONS.find(
            (animation) => animation.animationKey === animationSplitUp
        );
        if (animation) {
            animations.push(animation);
        }
    }

    //Remove animations from args
    return animations;
};

export const createVideoItemText = (
    args: any,
    videoSettings: VideoSettings
): VideoTrack => {
    let videoTrackStandard: VideoTrack = {
        videoChannelId: "channel1",
        id: Math.random().toString(),
        name: "text",
        frameLength: 30,
        firstFrame: 0,
        lastFrame: 30,
        frameLengthDriven: true,
        y: 0,
        x: 0,
        value: "Hello",
        color: getColor(args.value) || "#4A3232",
        fontColor: "#ffffff",
        fontSize: 300,
        fontFamily: "Helvetica",
        fontWeight: "bold",
        placementHorizontal: "center",
        placementVertical: "center",
        items: [],
        animations: [],
        width: 200,
        height: 200,
    };

    //Add animations
    const argsAnimation = args.animations;
    if (argsAnimation) {
        videoTrackStandard.animations =
            onFindAndAddAnimationFromString(argsAnimation);
        //Remove animations from args so it doesnt overwrite standard
        delete args.animations;
    }

    videoTrackStandard.y = onPlaceVertical(
        videoTrackStandard,
        args.placementHorizontal
            ? args.placementHorizontal
            : videoTrackStandard.placementHorizontal,
        videoSettings
    );

    videoTrackStandard.x = onPlaceHorizontal(
        videoTrackStandard,
        args.placementVertical
            ? args.placementVertical
            : videoTrackStandard.placementVertical,
        videoSettings
    );

    let videoTrack: VideoTrack = {
        ...videoTrackStandard,
        ...args,
    };
    return videoTrack;
};

export const createVideoItemBackground = (args: any): VideoTrack => {
    let videoTrackStandard: VideoTrack = {
        videoChannelId: "channel1",
        id: Math.random().toString(),
        name: "background",
        frameLength: 10,
        firstFrame: 0,
        lastFrame: 10,
        frameLengthDriven: true,
        y: 0,
        x: 0,
        value: "#4A3232",
        color: getColor(args.value) || "#4A3232",
        fontColor: "#ffffff",
        fontSize: 300,
        fontFamily: "Helvetica",
        fontWeight: "bold",
        placementHorizontal: "center",
        placementVertical: "center",
        items: [],
        animations: [],
        width: 200,
        height: 200,
    };

    //Add animations
    const argsAnimation = args.animations;
    if (argsAnimation) {
        videoTrackStandard.animations =
            onFindAndAddAnimationFromString(argsAnimation);
        //Remove animations from args so it doesnt overwrite standard
        delete args.animations;
    }
    let videoTrack: VideoTrack = {
        ...videoTrackStandard,
        ...args,
    };
    return videoTrack;
};

export const createVideoItemImage = (args: any): VideoTrack => {
    let videoTrackStandard: VideoTrack = {
        videoChannelId: "channel1",
        id: Math.random().toString(),
        name: "image",
        frameLength: 60,
        firstFrame: 0,
        lastFrame: 30,
        frameLengthDriven: true,
        y: 0,
        x: 0,
        value: "",
        color: getColor(args.value) || "#4A3232",
        fontColor: "#ffffff",
        fontSize: 300,
        fontFamily: "Helvetica",
        fontWeight: "bold",
        placementHorizontal: "center",
        placementVertical: "center",
        items: [],
        animations: [],
        width: 200,
        height: 200,
    };

    //Add animations
    if (args?.animations) {
        videoTrackStandard.animations =
            onFindAndAddAnimationFromString(args.animations);
        //Remove animations from args so it doesnt overwrite standard
        delete args.animations;
    }
    let videoTrack: VideoTrack = {
        ...videoTrackStandard,
        ...args,
    };
    return videoTrack;
};
export const createVideoItemTransition = (args: any): VideoTrack => {
    let videoTrackStandard: VideoTrack = {
        videoChannelId: "channel1",
        id: Math.random().toString(),
        name: "transition",
        frameLength: 10,
        firstFrame: 0,
        lastFrame: 10,
        frameLengthDriven: true,
        y: 0,
        x: 0,
        value: "fadeover",
        color: getColor(args.value) || "#4A3232",
        fontColor: "#ffffff",
        fontSize: 300,
        fontFamily: "Helvetica",
        fontWeight: "bold",
        placementHorizontal: "center",
        placementVertical: "center",
        items: [],
        animations: [],
        width: 200,
        height: 200,
    };

    //Add animations
    const argsAnimation = args.animations;
    if (argsAnimation) {
        videoTrackStandard.animations =
            onFindAndAddAnimationFromString(argsAnimation);
        //Remove animations from args so it doesnt overwrite standard
        delete args.animations;
    }
    let videoTrack: VideoTrack = {
        ...videoTrackStandard,
        ...args,
    };
    return videoTrack;
};
