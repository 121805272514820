import * as React from "react";

import { cn } from "../../../lib/utils";

interface LeftSidebarCardClickableProps
    extends React.HTMLProps<HTMLDivElement> {
    className?: string;
    children?: React.ReactNode;
}

const LeftSidebarCardClickable = ({
    className,
    children,
    ...props
}: LeftSidebarCardClickableProps) => (
    <div
        className={cn(
            "p-2 border relative rounded-lg border-zinc-800 hover:border-zinc-700 cursor-pointer hover:bg-zinc-800/20 transition-all",
            className
        )}
        {...props}
    >
        {children}
    </div>
);

export { LeftSidebarCardClickable };
