import { twMerge } from "tailwind-merge";
import { CanvasState, VideoSettings, VideoTrack } from "../../lib/models";
import { LeftSidebarCardClickable } from "../ui/leftsidebar/leftsidebar-card-clickable";
import { onAddAnimationToVideoItem } from "../../lib/video-util";
import { TimelineText } from "./Timeline/TimelineText";
import { TimelineImage } from "./Timeline/TimelineImage";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useStrictDroppable } from "../hooks/useStrictDroppable";
import { ImageIcon, TrashIcon } from "lucide-react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";

interface TimelineProps {
    videoTracks: VideoTrack[];
    videoTrackIndexSelected: number;
    setVideoTrackIndexSelected: (index: number) => void;
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
    canvasState: CanvasState;
    videoSettings: VideoSettings;
    setVideoSettings: (videoSettings: VideoSettings) => void;
    setCanvasState: (canvasState: CanvasState) => void;
    onSetTotalTimeFromVideoTracks: (videoTracks: VideoTrack[]) => void;
    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack: (
        videoTracks: VideoTrack[]
    ) => VideoTrack[];
    onSetVideoTrackFrameLengthToSec: (index: number, sec: number) => void;
    onAddNewItemBelow: (index: number, type: string) => void;
    onSelectFrameToPlayFrom: (
        index: number,
        shouldPlay?: boolean,
        runnableTimerStart?: number,
        runnableTimerMax?: number
    ) => void;
}

export const Timeline = ({
    videoTracks,
    videoTrackIndexSelected,
    setVideoTrackIndexSelected,
    setVideoTracks,
    setCanvasState,
    setVideoSettings,
    canvasState,
    videoSettings,
    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack,
    onSetTotalTimeFromVideoTracks,
    onSetVideoTrackFrameLengthToSec,
    onAddNewItemBelow,
    onSelectFrameToPlayFrom,
}: TimelineProps) => {
    const [enabled] = useStrictDroppable(videoTracks === null);

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let _videoTracks = Array.from(videoTracks);
        const [reorderedItem] = _videoTracks.splice(result.source.index, 1);
        _videoTracks.splice(result.destination.index, 0, reorderedItem);

        _videoTracks =
            onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack(
                _videoTracks
            );
        _videoTracks.map((videoTrack, index) => {
            videoTrack = onAddAnimationToVideoItem(videoSettings, videoTrack);
            return videoTrack;
        });

        setVideoTracks(_videoTracks);
    };

    const onDeleteItem = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        index: number
    ) => {
        e.stopPropagation();
        e.preventDefault();
        if (videoTracks.length !== 2) {
            let _videoTracks = [...videoTracks];
            _videoTracks.splice(index, 1);

            _videoTracks =
                onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack(
                    _videoTracks
                );
            onSetTotalTimeFromVideoTracks(_videoTracks);

            setVideoTracks(_videoTracks);
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {enabled && (
                <Droppable droppableId="droppable">
                    {(provided: any, snapshot: any) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="flex flex-col gap-0"
                        >
                            {videoTracks
                                .filter(
                                    (videoTrack) =>
                                        videoTrack.name === "text" ||
                                        videoTrack.name === "image" ||
                                        videoTrack.name === "transition"
                                )
                                .map((videoTrack, index) => (
                                    <Draggable
                                        key={videoTrack.id}
                                        draggableId={videoTrack.id}
                                        index={index}
                                    >
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <LeftSidebarCardClickable
                                                    className={twMerge(
                                                        "px-3 py-3 sidebar-display  relative rounded-lg border-none hover:border hover:border-zinc-600 cursor-pointer hover:bg-zinc-800/40 transition-all",
                                                        videoTrackIndexSelected ===
                                                            index
                                                            ? "bg-zinc-800/40 shadow-lg"
                                                            : ""
                                                    )}
                                                    tabIndex={index}
                                                    onFocus={() => {
                                                        setVideoTrackIndexSelected(
                                                            index
                                                        );
                                                        onSelectFrameToPlayFrom(
                                                            videoTrack.firstFrame
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        setVideoTrackIndexSelected(
                                                            index
                                                        );
                                                        onSelectFrameToPlayFrom(
                                                            videoTrack.firstFrame
                                                        );
                                                    }}
                                                    style={{
                                                        backgroundColor:
                                                            videoTrackIndexSelected ===
                                                            index
                                                                ? "rgb(44 44 54)"
                                                                : canvasState.runnableTimer >=
                                                                      videoTrack.firstFrame &&
                                                                  canvasState.runnableTimer <=
                                                                      videoTrack.lastFrame
                                                                ? "rgb(44 44 54)"
                                                                : "",
                                                    }}
                                                >
                                                    <button
                                                        className="absolute sidebar-display-children p-2 z-30 -right-3 -top-4 "
                                                        onClick={(e) =>
                                                            onDeleteItem(
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <TrashIcon className="h-4 w-4 text-zinc-600 hover:text-red-500" />
                                                    </button>
                                                    {videoTrack.name ===
                                                        "text" && (
                                                        <TimelineText
                                                            videoTrack={
                                                                videoTrack
                                                            }
                                                            index={index}
                                                            videoTracks={
                                                                videoTracks
                                                            }
                                                            setVideoSettings={
                                                                setVideoSettings
                                                            }
                                                            videoTrackIndexSelected={
                                                                videoTrackIndexSelected
                                                            }
                                                            setVideoTrackIndexSelected={
                                                                setVideoTrackIndexSelected
                                                            }
                                                            setVideoTracks={
                                                                setVideoTracks
                                                            }
                                                            setCanvasState={
                                                                setCanvasState
                                                            }
                                                            canvasState={
                                                                canvasState
                                                            }
                                                            videoSettings={
                                                                videoSettings
                                                            }
                                                            onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack={
                                                                onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack
                                                            }
                                                            onSetVideoTrackFrameLengthToSec={
                                                                onSetVideoTrackFrameLengthToSec
                                                            }
                                                        />
                                                    )}
                                                    {videoTrack.name ===
                                                        "image" && (
                                                        <TimelineImage
                                                            videoTrack={
                                                                videoTrack
                                                            }
                                                            index={index}
                                                            videoTracks={
                                                                videoTracks
                                                            }
                                                            videoTrackIndexSelected={
                                                                videoTrackIndexSelected
                                                            }
                                                            setVideoTrackIndexSelected={
                                                                setVideoTrackIndexSelected
                                                            }
                                                            setVideoSettings={
                                                                setVideoSettings
                                                            }
                                                            setVideoTracks={
                                                                setVideoTracks
                                                            }
                                                            setCanvasState={
                                                                setCanvasState
                                                            }
                                                            canvasState={
                                                                canvasState
                                                            }
                                                            videoSettings={
                                                                videoSettings
                                                            }
                                                            onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack={
                                                                onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack
                                                            }
                                                            onSetVideoTrackFrameLengthToSec={
                                                                onSetVideoTrackFrameLengthToSec
                                                            }
                                                        />
                                                    )}
                                                </LeftSidebarCardClickable>
                                                <div className="sidebar-display-items mx-2 overflow-hidden transition-all flex flex-row gap-0 justify-center items-center">
                                                    <div className="sidebar-display-items-children pl-2 h-4 items-center border-t border-b border-zinc-700">
                                                        <ChevronDoubleRightIcon className="h-3 w-3 stroke-1 text-zinc-600" />
                                                    </div>
                                                    <button
                                                        tabIndex={-1}
                                                        className="w-full text-center items-center h-4 hover:h-10 transition-all pr-4 flex justify-center sidebar-display-items-children  border-t border-b border-zinc-700"
                                                        onClick={() =>
                                                            onAddNewItemBelow(
                                                                index,
                                                                "text"
                                                            )
                                                        }
                                                    >
                                                        <span className="flex-row flex justify-center items-center text-zinc-200 text-2xs px-2 py-1 gap-2">
                                                            T Add text
                                                        </span>
                                                    </button>
                                                    <button
                                                        tabIndex={-1}
                                                        className="w-full text-center items-center h-10 transition-all pr-4 flex justify-center sidebar-display-items-children  border-t border-b border-zinc-700"
                                                        onClick={() =>
                                                            onAddNewItemBelow(
                                                                index,
                                                                "image"
                                                            )
                                                        }
                                                    >
                                                        <span className="flex-row flex justify-center items-center text-zinc-200 text-2xs px-2 py-1 gap-2">
                                                            <ImageIcon className="h-3 w-3 stroke-1" />
                                                            <span>
                                                                Add image
                                                            </span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            )}
        </DragDropContext>
    );
};
