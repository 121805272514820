import { PauseIcon, PlayIcon } from "lucide-react";
import { Button } from "../ui/button";
import { Dialog, DialogContent } from "../ui/dialog";
import { useRef, useState } from "react";
import { AudioTrack, VideoSettings } from "../../lib/models";
import { AUDIO_TRACKS } from "../../lib/audiotracks.constant";

interface MusicDialogProps {
    videoSettings: VideoSettings;
    isMusicDialog: boolean;
    setMusicDialog: (isMusicDialog: boolean) => void;
    setVideoSettings: (videoSetting: VideoSettings) => void;
}

export const MusicDialog = ({
    isMusicDialog,
    setMusicDialog,
    videoSettings,
    setVideoSettings,
}: MusicDialogProps) => {
    const audioTestRef = useRef<HTMLAudioElement | null>(null);
    const [audioTestTrack, setAudioTestTrack] = useState("");
    const onCloseMusicDialog = () => {
        audioTestRef.current?.pause();
        setMusicDialog(false);
    };
    const onPlaySingleTrack = (trackUrl: string) => {
        if (audioTestTrack === trackUrl) {
            setTimeout(() => {
                setAudioTestTrack("");
            }, 0);
        } else {
            setAudioTestTrack(trackUrl);
        }

        setTimeout(() => {
            if (audioTestRef.current?.paused) {
                audioTestRef.current?.play();
            } else {
                audioTestRef.current?.pause();
            }
        }, 0);
    };
    const onSelectAudioTrackToVideo = (audioTrack: AudioTrack) => {
        setVideoSettings({
            ...videoSettings,
            audioTrack: audioTrack,
        });

        audioTestRef.current?.pause();
        setTimeout(() => {
            setAudioTestTrack("");
        }, 0);
    };

    const onAudioItemPlay = (audioTrack: AudioTrack) => {
        return (
            <div
                className="flex flex-row justify-between w-full gap-2"
                key={audioTrack.url}
            >
                <div className="flex flex-row gap-3 items-center">
                    <button
                        className="rounded-full h-8 w-8 bg-zinc-700 flex items-center justify-center"
                        onClick={() => onPlaySingleTrack(audioTrack.url)}
                    >
                        {audioTestTrack === audioTrack.url ? (
                            <PauseIcon className="w-3 h-3 text-white " />
                        ) : (
                            <PlayIcon className="w-3 h-3 text-white " />
                        )}
                    </button>
                    <div className="flex flex-col text-white">
                        <p className="text-base">{audioTrack.title}</p>
                        <p className="text-zinc-400 text-sm">
                            {audioTrack.description}
                        </p>
                    </div>
                </div>
                <Button
                    variant={"secondary"}
                    onClick={() => onSelectAudioTrackToVideo(audioTrack)}
                    disabled={videoSettings.audioTrack.url === audioTrack.url}
                >
                    {videoSettings.audioTrack.url === audioTrack.url
                        ? "Selected"
                        : "Select"}
                </Button>
            </div>
        );
    };

    return (
        <>
            <audio
                ref={audioTestRef}
                src={"./" + audioTestTrack}
                preload="metadata"
            ></audio>
            <Dialog open={isMusicDialog} onOpenChange={onCloseMusicDialog}>
                <DialogContent>
                    <div className="flex flex-col  gap-4 w-full items-end justify-end">
                        <div className="w-full flex justify-start items-start ">
                            <div className="w-full flex flex-col">
                                <h1 className="dark:text-white font-semibold text-2xl tracking-tighter">
                                    Add music to your video
                                </h1>
                                <p className="text-zinc-400">
                                    Here is a list of royalty free music you can
                                    choose from.
                                </p>
                                <div className="border flex flex-row gap-2 justify-between items-center rounded px-4 py-4 border-zinc-800 mt-6">
                                    <div className="flex flex-col text-white">
                                        <p className="text-base">
                                            {AUDIO_TRACKS[0].title}
                                        </p>
                                        <p className="text-zinc-400 text-sm">
                                            {AUDIO_TRACKS[0].description}
                                        </p>
                                    </div>
                                    <Button
                                        variant={"secondary"}
                                        onClick={() =>
                                            onSelectAudioTrackToVideo(
                                                AUDIO_TRACKS[0]
                                            )
                                        }
                                        disabled={
                                            videoSettings.audioTrack.url ===
                                            AUDIO_TRACKS[0].url
                                        }
                                    >
                                        {videoSettings.audioTrack.url ===
                                        AUDIO_TRACKS[0].url
                                            ? "Selected"
                                            : "Select"}
                                    </Button>
                                </div>
                                <div className="flex w-full flex-col mt-6 gap-4">
                                    {AUDIO_TRACKS.filter(
                                        (audioTrack) =>
                                            audioTrack.url !== "silence.mp3"
                                    ).map((track) => onAudioItemPlay(track))}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
