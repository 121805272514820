import { AudioTrack } from "./models";

export const AUDIO_TRACKS: AudioTrack[] = [
    {
        title: "No music",
        description: "Just silence",
        url: "silence.mp3",
    },
    {
        title: "Super Lo-fi",
        description: "Chill easy going music",
        url: "lofi.mp3",
    },
    {
        title: "Future Bass",
        description: "Chill easy going music",
        url: "futurebass.mp3",
    },
];
