import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { usePostHog } from "posthog-js/react";

const ExamplesPage = () => {
    const posthog = usePostHog();
    posthog?.capture("examples_page_viewed");

    const onLogoClick = () => {
        posthog?.capture("examples_page_logo_clicked");
    };
    const onStartClick = () => {
        posthog?.capture("examples_page_button_clicked");
    };

    return (
        <div className="min-h-screen p-8">
            <div className="mt-20 md:mt-28 flex flex-col gap-2 items-start mx-auto max-w-4xl w-full">
                <Link to="/" onClick={onLogoClick}>
                    <img
                        src="/logo-full.png"
                        className="h-4"
                        alt="QuickEdit.ai logo"
                    />
                </Link>
                <h1 className="text-5xl mt-10 mb-4 md:text-8xl text-zinc-50  tracking-tight font-bold max-w-xl">
                    Create anything you want
                </h1>
                <h2 className="text-sm md:text-base text-zinc-400 max-w-sm">
                    There’s a world of possibilities when it comes to the videos
                    you can create with QuickEdit. Here are some examples, for
                    inspiration.
                </h2>
                <div className="flex flex-col md:flex-row gap-6 mt-10 mb-20">
                    <div className="flex flex-col gap-6">
                        <video autoPlay muted loop className="rounded-lg">
                            <source
                                src={"/example-videos/welcome-video.mp4"}
                                type="video/mp4"
                            />
                        </video>
                        <video autoPlay muted loop className="rounded-lg">
                            <source
                                src={"/example-videos/Introducing-Acme.mp4"}
                                type="video/mp4"
                            />
                        </video>
                        <video autoPlay muted loop className="rounded-lg">
                            <source
                                src={"/example-videos/hiring-video.mp4"}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className="flex flex-col gap-6">
                        <video autoPlay muted loop className="rounded-lg">
                            <source
                                src={"/example-videos/QuickExpense.mp4"}
                                type="video/mp4"
                            />
                        </video>
                        <video autoPlay muted loop className="rounded-lg">
                            <source
                                src={"/example-videos/ecommerce-ad.mp4"}
                                type="video/mp4"
                            />
                        </video>
                        <Link to="/" onClick={onStartClick}>
                            <button className="w-full text-white bg-blue-600 hover:bg-blue-600/80 rounded-lg h-[68px] font-medium flex flex-row items-center justify-center gap-3">
                                <span>Create your video</span>
                                <ArrowRightIcon className="h-4 w-4 stroke-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <h3 className="text-5xl mt-10 mb-4 md:text-8xl text-zinc-50  tracking-tight font-bold max-w-xl">
                    Created something cool? Send it over.
                </h3>
                <p className="text-sm md:text-base text-zinc-400 max-w-sm mb-52">
                    We’d like to showcase more work.{" "}
                    <a
                        href="mailto:sam@samlafontaine.com"
                        className="underline underline-offset-2"
                    >
                        Email us
                    </a>{" "}
                    your creation, and if we like it, we’ll add it to this page.
                </p>
            </div>
        </div>
    );
};

export default ExamplesPage;
