import * as React from "react";

import { cn } from "../../lib/utils";
import { ImageIcon, ImagePlusIcon } from "lucide-react";

export interface InputFileProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    classNameContainer?: string;
}

const InputFile = React.forwardRef<HTMLInputElement, InputFileProps>(
    ({ classNameContainer, className, label, ...props }, ref) => {
        const [fileSelected, setFileSelected] = React.useState<any>(null);
        return (
            <div
                className={cn(
                    "bg-white border h-10 flex items-center border-zinc-200 dark:border-zinc-800 dark:bg-zinc-900  p-2 rounded-md relative",
                    classNameContainer ? classNameContainer : ""
                )}
            >
                <label
                    htmlFor="inputFile"
                    className="cursor-pointer h-10 flex items-center text-sm dark:text-zinc-300 dark:hover:text-zinc-500"
                >
                    {fileSelected ? (
                        <span className="flex flex-row gap-2 items-center text-xs">
                            <ImageIcon className="w-4 h-4" />{" "}
                            {fileSelected.name}
                        </span>
                    ) : (
                        <span className="flex flex-row gap-2 items-center">
                            <ImagePlusIcon className="w-4 h-4" />{" "}
                            {label ? label : "Upload images"}
                        </span>
                    )}
                    <input
                        name="inputFile"
                        type="file"
                        className={cn(
                            "flex opacity-0 absolute inset-0  dark:text-zinc-50 h-10 w-full text-zinc-950 bg-white rounded-md border border-zinc-200  px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-900 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-zinc-700 dark:bg-zinc-800 dark:ring-offset-zinc-200 dark:placeholder:text-zinc-400 dark:focus-visible:ring-zinc-300",
                            className
                        )}
                        accept="image/png, image/jpeg"
                        ref={ref}
                        
                        {...props}
                        onChange={(e) => {
                            if (props?.onChange) {
                                props.onChange(e);
                            }
                            const file = e.target.files![0];
                            console.log("file", file);

                            if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    const img = new Image();
                                    img.onload = function () {
                                        setFileSelected({
                                            base64: e.target!.result as string,
                                            name: file.name,
                                            imageWidth: img.width,
                                            imageHeight: img.height,
                                        });
                                    };
                                    img.src = e.target!.result as string;
                                };
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                </label>
            </div>
        );
    }
);
InputFile.displayName = "InputFile";

export { InputFile };
