import { useCallback, useEffect, useRef, useState } from "react";
import {
    VideoSettings,
    VideoTrack,
    VideoItem,
    CanvasState,
} from "../../lib/models";
import {
    calculcateWidthAndHeight,
    onAddAnimationToVideoItem,
    onUpdateWidthAndHeightOnVideoTracks,
} from "../../lib/video-util";
import { MoveIcon } from "lucide-react";
import { twMerge } from "tailwind-merge";

interface VideoCanvasProps {
    videoSettings: VideoSettings;
    canvasSettings: any;
    canvasState: CanvasState;
    canvasRef: React.RefObject<HTMLCanvasElement>;
    videoTracks: VideoTrack[];
    setVideoTrackIndexSelected: (index: number) => void;
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
    runnableTimer: number;
}
const VideoCanvas = ({
    videoSettings,
    canvasSettings,
    canvasRef,
    videoTracks,
    canvasState,
    setVideoTrackIndexSelected,
    setVideoTracks,
    runnableTimer,
}: VideoCanvasProps) => {
    const [canvasIndexSelected, setCanvasIndexSelected] = useState(-1);
    const [dragEnabled, setDragEnabled] = useState(false);
    const [clickX, setClickX] = useState(-1);
    const [clickY, setClickY] = useState(-1);
    const [clickHeight, setClickHeight] = useState(200);
    const [clickWidth, setClickWidth] = useState(200);
    const canvasILRef = useRef<HTMLDivElement>(null);
    const [videoTracksCanvas, setVideoTracksCanvas] = useState(videoTracks);

    useEffect(() => {
        // onUpdateWidthAndHeightOnVideoTracks(videoTracks, canvasRef).then(
        //     (videoTracksUpdated) => {
        setVideoTracksCanvas(videoTracks);
        // }
        // );
    }, [videoTracks, canvasRef]);

    const onDrawInterfaceLayer = async (videoTrackIndex: number) => {
        if (videoTrackIndex !== -1) {
            setVideoTrackIndexSelected(videoTrackIndex);

            const _videoTrack = videoTracksCanvas[videoTrackIndex];

            const _videoTrackItemInFrame = _videoTrack.items.find(
                (item: VideoItem) => {
                    return item.frame == runnableTimer;
                }
            );

            const x = _videoTrackItemInFrame?.x || _videoTrack.x || 0;
            const y = _videoTrackItemInFrame?.y || _videoTrack.y || 0;

            if (_videoTrack.name === "text") {
                const textSizes = await calculcateWidthAndHeight(
                    _videoTrack,
                    canvasRef
                );
                setClickX(Math.round((x || 0) - textSizes.width / 2));
                setClickY(Math.round((y || 0) - textSizes.height / 2));
                setClickWidth(textSizes.width);
                setClickHeight(textSizes.height);
            } else {
                setClickX(Math.round(x ? x : 0));
                setClickY(Math.round(y ? y : 0));
                setClickWidth(_videoTrack.width || 100);
                setClickHeight(_videoTrack.height || 100);
            }
        }
    };

    const onMouseDownOnCanvas = async (e: React.MouseEvent<HTMLDivElement>) => {
        if (dragEnabled) {
            return;
        }
        const rect = canvasILRef.current!.getBoundingClientRect();
        //Find place where I have clicked on the canvasILRef
        const x = Math.round((e.clientX - rect.left) / +canvasSettings.scale);
        const y = Math.round((e.clientY - rect.top) / +canvasSettings.scale);

        const videoTrackIndex = videoTracksCanvas.findIndex(
            (videoTrack: VideoTrack) => {
                const foundItem = videoTrack.items.findIndex(
                    (videoItem: VideoItem) => {
                        if (
                            videoItem.y &&
                            videoItem.x &&
                            videoTrack.width &&
                            videoTrack.height &&
                            videoTrack.firstFrame <= runnableTimer &&
                            videoTrack.lastFrame >= runnableTimer &&
                            videoTrack.name !== "background"
                        ) {
                            let itemX = videoItem.x;
                            let itemY = videoItem.y;

                            //Need to calculate for text if baseline is center or bottom

                            let width = videoTrack.width || 100;
                            let height = videoTrack.width || 100;
                            if (videoTrack.name === "text") {
                                //Because if text has baseline center, then text i centered in total width
                                itemX = videoItem.x - videoTrack.width / 2;
                                itemY = videoItem.y - videoTrack.height / 2;
                            }

                            if (
                                itemX <= x &&
                                itemY <= y &&
                                itemX + width >= x &&
                                itemY + height >= y
                            ) {
                                console.log(
                                    ` item.x ${itemX} item.y ${itemY}  item.width ${
                                        itemX + width
                                    } item.height ${itemY + height} item.name ${
                                        videoTrack.name
                                    } `
                                );
                                return true;
                            }
                        }

                        return false;
                    }
                );
                console.log("foundItem", foundItem);

                if (foundItem !== -1) {
                    return true;
                } else {
                    return false;
                }
            }
        );

        if (videoTrackIndex !== -1) {
            setCanvasIndexSelected(videoTrackIndex);
            onDrawInterfaceLayer(videoTrackIndex);
        } else {
            setCanvasIndexSelected(-1);
        }
    };

    const onMouseMoveOnCanvas = (e: any) => {
        const rect = canvasILRef.current!.getBoundingClientRect();

        const x = Math.round((e.clientX - rect.left) / +canvasSettings.scale);
        const y = Math.round((e.clientY - rect.top) / +canvasSettings.scale);

        if (canvasIndexSelected !== -1 && dragEnabled) {
            setClickX(x);
            setClickY(y);
        }
    };

    // useEffect(() => {
    //     onDrawInterfaceLayer(videoTrackIndexSelected);
    // }, [videoTrackIndexSelected, videoTracks]);

    const onMouseUpOnCanvas = async (e: any) => {
        if (canvasIndexSelected !== -1 && dragEnabled) {
            const rect = canvasILRef.current!.getBoundingClientRect();

            const x = (e.clientX - rect.left) / +canvasSettings.scale;
            const y = (e.clientY - rect.top) / +canvasSettings.scale;

            const _videoTracks = [...videoTracksCanvas];
            let _videoTrack = _videoTracks[canvasIndexSelected];

            if (_videoTrack.name === "text") {
                const textSizes = await calculcateWidthAndHeight(
                    _videoTrack,
                    canvasRef
                );
                _videoTrack.x = Math.round(x + textSizes.width / 2);
                _videoTrack.y = Math.round(y + textSizes.height / 2);
            } else {
                _videoTrack.x = Math.round(x);
                _videoTrack.y = Math.round(y);
            }

            _videoTrack = onAddAnimationToVideoItem(
                videoSettings,
                _videoTrack,
                _videoTrack.animations || []
            );
            setVideoTracksCanvas(_videoTracks);
            setVideoTracks(_videoTracks);
        }
    };

    const onDragMouseDown = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnabled(true);
    };

    const onDragMouseLeave = (e: any) => {
        setDragEnabled(false);
    };

    return (
        <>
            <div
                ref={canvasILRef}
                className="absolute z-40"
                style={{
                    scale: canvasSettings.scale,
                    width: videoSettings.width,
                    minWidth: videoSettings.width,
                    height: videoSettings.height,
                    maxHeight: videoSettings.height,
                }}
                onMouseMove={(e) => onMouseMoveOnCanvas(e)}
                onMouseDown={(e) => onMouseDownOnCanvas(e)}
                onMouseUp={(e) => onMouseUpOnCanvas(e)}
            >
                {canvasIndexSelected !== -1 && !canvasState.playing && (
                    <div
                        style={{
                            left: clickX,
                            top: clickY,
                            width: clickWidth,
                            height: clickHeight,
                        }}
                        className={twMerge(
                            "absolute rounded-lg z-50 border border-dashed flex justify-start items-start flex-col",
                            dragEnabled
                                ? "border-white bg-white/30"
                                : " border-white/80 bg-white/20"
                        )}
                    >
                        <span
                            onMouseDown={onDragMouseDown}
                            onMouseUp={onDragMouseLeave}
                            className="select-none cursor-move p-4"
                        >
                            <MoveIcon className="w-8 h-8 text-white" />
                        </span>
                        <div className="absolute -top-8  text-white -left-0 text-base justify-start items-start flex-col">
                            <span>x: {clickX}</span>
                            <span>y: {clickY}</span>
                        </div>
                    </div>
                )}
            </div>
            <canvas
                ref={canvasRef}
                width={videoSettings.width}
                height={videoSettings.height}
                className="rounded-3xl shadow-2xl"
                style={{
                    scale: canvasSettings.scale,
                }}
            ></canvas>
        </>
    );
};
export default VideoCanvas;
