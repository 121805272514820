import { VideoItemAnimation, VideoSettings, VideoTrack } from "./models";
import { getColor } from "./utils";
import { onPlaceHorizontal, onPlaceVertical } from "./video-util";

export const createDummyTextData = (
    videoSettings: VideoSettings,
    name: string,
    firstFrame: number,
    lastFrame: number,
    value: string,
    animations: VideoItemAnimation[],
    args: any
): VideoTrack => {
    let videoTrackText: VideoTrack = {
        videoChannelId: "videochannel1",
        id: Math.random().toString(),
        frameLength: lastFrame - firstFrame,
        frameLengthDriven: true,
        name: "text",
        firstFrame: firstFrame,
        lastFrame: lastFrame,
        y: 0,
        x: 0,
        value: value,
        color: "#4A3232",
        fontColor: "#FFFFFF",
        fontSize: 350,
        fontFamily: "Helvetica",
        fontWeight: "bold",
        placementHorizontal: "center",
        placementVertical: "center",
        items: [],
        animations: animations || [],
        width: 466,
        height: 281,
        rotation: 0,
    };
    videoTrackText.y = onPlaceVertical(videoTrackText, "center", videoSettings);
    videoTrackText.x = onPlaceHorizontal(
        videoTrackText,
        "center",
        videoSettings
    );

    let videoTrack: VideoTrack = {
        ...videoTrackText,
        ...args,
    };
    return videoTrack;
};

const getDummyData = (
    videoSettings: VideoSettings,
    type: string,
    firstFrame: number,
    lastFrame: number,
    value: string,
    fontColor?: string,
    color?: string,
    animations?: VideoItemAnimation[],
    width?: number,
    height?: number,
    x?: number,
    y?: number,
    frameLength?: number
): VideoTrack => {
    if (type === "rectangle") {
        let videoTrackRectangle: VideoTrack = {
            videoChannelId: "videochannel1",
            id: Math.random().toString(),
            frameLength: frameLength ? frameLength : lastFrame - firstFrame,
            frameLengthDriven: true,
            name: "rectangle",
            firstFrame: firstFrame,
            lastFrame: lastFrame,
            y: y || 0,
            x: x || 0,
            value: value,
            color: color || getColor(value) || "#4A3232",
            fontColor: fontColor || "#4A3232",
            fontSize: 0,
            fontFamily: "Helvetica",
            fontWeight: "bold",
            placementHorizontal: "center",
            placementVertical: "center",
            items: [],
            animations: animations || [],
            rotation: 0,
            width: width ? width : videoSettings.width,
            height: height ? height : videoSettings.height,
        };

        return videoTrackRectangle;
    } else if (type === "background") {
        let videoTrackBG: VideoTrack = {
            videoChannelId: "videochannel2",
            id: Math.random().toString(),
            frameLength: lastFrame - firstFrame,
            frameLengthDriven: true,
            name: "background",
            firstFrame: firstFrame,
            lastFrame: lastFrame,
            y: 0,
            x: 0,
            value: value,
            color: color || getColor(value) || "#4A3232",
            fontColor: fontColor || "#4A3232",
            fontSize: 0,
            fontFamily: "Helvetica",
            fontWeight: "bold",
            placementHorizontal: "center",
            placementVertical: "center",
            items: [],
            animations: animations || [],
            rotation: 0,
            width: videoSettings.width,
            height: videoSettings.height,
        };
        videoTrackBG.y = onPlaceVertical(videoTrackBG, "center", videoSettings);
        videoTrackBG.x = onPlaceHorizontal(
            videoTrackBG,
            "center",
            videoSettings
        );
        return videoTrackBG;
    } else if (type === "image") {
        let videoTrackBG: VideoTrack = {
            videoChannelId: "videochannel4",
            id: Math.random().toString(),
            frameLength: lastFrame - firstFrame,
            frameLengthDriven: true,
            name: "image",
            firstFrame: firstFrame,
            lastFrame: lastFrame,
            y: y || 10,
            x: x || 10,
            value: value,
            color: color || getColor(value) || "#4A3232",
            fontColor: fontColor || "#4A3232",
            fontSize: 0,
            fontFamily: "Helvetica",
            fontWeight: "bold",
            placementHorizontal: "start",
            placementVertical: "start",
            items: [],
            animations: animations || [],
            width: width || 192,
            height: height || 192,
            rotation: 0,
        };
        if (!x) {
            videoTrackBG.y = onPlaceVertical(
                videoTrackBG,
                "start",
                videoSettings
            );
            videoTrackBG.x = onPlaceHorizontal(
                videoTrackBG,
                "start",
                videoSettings
            );
        }

        return videoTrackBG;
    } else {
        let videoTrackText: VideoTrack = {
            videoChannelId: "videochannel3",
            id: Math.random().toString(),
            frameLength: lastFrame - firstFrame,
            frameLengthDriven: true,
            name: "text",
            firstFrame: firstFrame,
            lastFrame: lastFrame,
            y: y || 0,
            x: x || 0,
            value: value,
            color: color || getColor(value) || "#4A3232",
            fontColor: fontColor || "#4A3232",
            fontSize: 350,
            fontFamily: "Helvetica",
            fontWeight: "bold",
            placementHorizontal: "center",
            placementVertical: "center",
            items: [],
            animations: animations || [],
            width: 466,
            height: 281,
            rotation: 0,
        };
        videoTrackText.y = onPlaceVertical(
            videoTrackText,
            "center",
            videoSettings
        );
        videoTrackText.x = onPlaceHorizontal(
            videoTrackText,
            "center",
            videoSettings
        );
        return videoTrackText;
    }
};

export default getDummyData;
