import { useState, useEffect } from "react";

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        isMobile: false,
        isTablet: false,
    });

    useEffect(() => {
        const handleResize = () => {
            if (window) {
                setScreenSize({
                    screenWidth: window.innerWidth,
                    screenHeight: window.innerHeight,
                    isMobile: window.innerWidth < 768,
                    isTablet:
                        window.innerWidth >= 768 && window.innerWidth < 1025,
                });
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return screenSize;
};

export default useScreenSize;
