import { ChevronDownIcon, ChevronRight, Plus, TrashIcon } from "lucide-react";
import ANIMATIONS from "../../../lib/animations.constant";
import {
    VideoItemAnimation,
    VideoSettings,
    VideoTrack,
} from "../../../lib/models";
import { onAddAnimationToVideoItem } from "../../../lib/video-util";

import { LabelSidebar } from "../../ui/sidebar/label";
import {
    SelectContentSidebar,
    SelectItemSidebar,
    SelectSidebar,
    SelectTriggerSidebar,
    SelectValueSidebar,
} from "../../ui/sidebar/select";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { useState } from "react";
import SidebarHR from "../sidebar/SidebarHR";

interface TimelineAnimtionProps {
    videoTracks: VideoTrack[];
    videoTrack: VideoTrack;
    videoTrackIndexSelected: number;
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
    setVideoSettings: (videoSettings: VideoSettings) => void;
    videoSettings: VideoSettings;
}

const TimelineAnimation = ({
    videoTracks,
    videoTrack,
    videoTrackIndexSelected,
    setVideoTracks,
    setVideoSettings,
    videoSettings,
}: TimelineAnimtionProps) => {
    const [showStartAnimations, setShowStartAnimations] = useState(false);
    const [showExitAnimations, setShowExitAnimations] = useState(false);
    const findIfAnimationIsAlreadyAdded = (animationValue: string) => {
        return videoTrack.animations?.find(
            (_animation) => _animation.animationValue === animationValue
        );
    };

    const onAddAnimationToVideoTrackAnimations = (
        animation: VideoItemAnimation,
        frameType: string
    ) => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];

        const animationFit = {
            ...animation,
        };

        const durationSeconds = 0.4 * videoSettings.framerate;

        if (frameType === "FIRST_FRAME") {
            animationFit.firstFrame = _videoTrack.firstFrame;
            animationFit.lastFrame = _videoTrack.firstFrame + durationSeconds;
            setShowStartAnimations(true);
        } else {
            animationFit.firstFrame = Math.abs(
                _videoTrack.lastFrame - durationSeconds
            );
            animationFit.lastFrame = _videoTrack.lastFrame;

            setShowExitAnimations(true);
        }

        _videoTrack.animations?.push(animationFit);

        _videoTrack = onAddAnimationToVideoItem(videoSettings, _videoTrack);

        setVideoTracks(_videoTracks);
    };

    const onRemoveAnimation = (animation: VideoItemAnimation) => {
        //Remove animation
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];

        _videoTrack.animations = _videoTrack.animations?.filter(
            (_animation) => _animation.animationKey !== animation.animationKey
        );

        _videoTrack = onAddAnimationToVideoItem(videoSettings, _videoTrack);
        setVideoTracks(_videoTracks);
    };

    const onChangeDurationExit = (
        val: string,
        animation: VideoItemAnimation
    ) => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];

        const durationSeconds = parseFloat(val);

        const indexOfAnimation: number =
            _videoTrack.animations?.findIndex(
                (_animation) =>
                    _animation.animationKey === animation.animationKey
            ) || 0;

        _videoTrack.animations![indexOfAnimation].firstFrame =
            _videoTrack.lastFrame - durationSeconds * videoSettings.framerate;
        _videoTrack.animations![indexOfAnimation].lastFrame =
            _videoTrack.lastFrame;

        _videoTrack = onAddAnimationToVideoItem(videoSettings, _videoTrack);
        setVideoTracks(_videoTracks);
    };
    const onChangeDurationStart = (
        val: string,
        animation: VideoItemAnimation
    ) => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];
        const durationSeconds = parseFloat(val);

        const indexOfAnimation: number =
            _videoTrack.animations?.findIndex(
                (_animation) =>
                    _animation.animationKey === animation.animationKey
            ) || 0;

        _videoTrack.animations![indexOfAnimation].firstFrame =
            _videoTrack.firstFrame;
        _videoTrack.animations![indexOfAnimation].lastFrame =
            _videoTrack.firstFrame + durationSeconds * videoSettings.framerate;

        _videoTrack = onAddAnimationToVideoItem(videoSettings, _videoTrack);
        setVideoTracks(_videoTracks);
    };

    return (
        <div className="flex items-start w-full flex-col">
            <div className="w-full">
                <SidebarHR></SidebarHR>
            </div>
            <div className="flex items-start w-full flex-col">
                <div className="flex items-start w-full flex-col gap-4 ">
                    <div className="flex flex-row w-full items-center justify-between text-xs text-zinc-200">
                        <button
                            className="flex py-2 w-full flex-row text-zinc-200 text-center hover:opacity-80"
                            onClick={() => {
                                setShowStartAnimations(!showStartAnimations);
                            }}
                        >
                            <span className="w-7 text-right flex justify-start pl-1">
                                {!showStartAnimations && (
                                    <ChevronRight className="w-3 h-3 text-zinc-400 " />
                                )}
                                {showStartAnimations && (
                                    <ChevronDownIcon className="w-3 h-3 text-zinc-400 " />
                                )}
                            </span>
                            <p className="text-xs ">
                                Start Animations
                                <span className="bg-zinc-700 px-2 py-1 rounded-lg ml-2 font-semibold text-2xs">
                                    {
                                        videoTrack.animations?.filter(
                                            (animation) =>
                                                animation.animationAppend ===
                                                "firstFrame"
                                        ).length
                                    }
                                </span>
                            </p>
                        </button>
                        <Popover>
                            <PopoverTrigger
                                onClick={(e) => e.stopPropagation()}
                                className="w-[55px] shrink-0 py-1 text-xs text-right hover:opacity-80  font-semibold"
                            >
                                Add
                                <Plus className="w-3 ml-2 h-3 inline-block" />
                            </PopoverTrigger>
                            <PopoverContent>
                                <div className="flex flex-col gap-1">
                                    {ANIMATIONS.filter(
                                        (animation) =>
                                            animation.animationAppend ===
                                            "firstFrame"
                                    )
                                        .filter(
                                            (animation) =>
                                                findIfAnimationIsAlreadyAdded(
                                                    animation.animationValue
                                                ) === undefined
                                        )
                                        .map((animation, index) => (
                                            <button
                                                key={"popani" + index}
                                                onClick={() =>
                                                    onAddAnimationToVideoTrackAnimations(
                                                        animation,
                                                        "FIRST_FRAME"
                                                    )
                                                }
                                                className="flex gap-4 flex-row items-center py-1 text-sm rounded-md text-zinc-200  hover:bg-zinc-800"
                                            >
                                                <img
                                                    src={`/example-animations/${animation.animationKey}.gif`}
                                                    className="h-10 w-10 rounded"
                                                />
                                                <span>
                                                    {animation.animationValue}
                                                </span>
                                            </button>
                                        ))}
                                    <button></button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                    {showStartAnimations && (
                        <div className="pl-7 pb-8 flex flex-col gap-6 w-full">
                            {videoTrack.animations
                                ?.filter(
                                    (animation) =>
                                        animation.animationAppend ===
                                        "firstFrame"
                                )
                                .map((animation, index) => (
                                    <div
                                        className="w-full flex flex-col gap-2"
                                        key={
                                            animation.animationKey + "" + index
                                        }
                                    >
                                        <div className="flex flex-row justify-between items-center gap-4 w-full">
                                            <span className="text-white text-xs font-semibold">
                                                {animation.animationValue}
                                            </span>
                                            <button
                                                onClick={() =>
                                                    onRemoveAnimation(animation)
                                                }
                                            >
                                                <TrashIcon className="w-3 h-3 text-zinc-500 cursor-pointer hover:opacity-80 hover:text-red-500" />
                                            </button>
                                        </div>
                                        <div className="flex flex-col items-start gap-1 w-full">
                                            <div className="flex flex-row items-center gap-4 w-full">
                                                <LabelSidebar className="w-[125px]">
                                                    Duration
                                                </LabelSidebar>{" "}
                                                <SelectSidebar
                                                    name="startDuration"
                                                    onValueChange={(val) => {
                                                        onChangeDurationStart(
                                                            val,
                                                            animation
                                                        );
                                                    }}
                                                    value={
                                                        "" +
                                                        ((animation?.lastFrame ||
                                                            0) -
                                                            (animation?.firstFrame ||
                                                                0)) /
                                                            30
                                                    }
                                                >
                                                    <SelectTriggerSidebar className="w-full h-8">
                                                        <SelectValueSidebar placeholder="0.2s" />
                                                    </SelectTriggerSidebar>
                                                    <SelectContentSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.1"}
                                                        >
                                                            0.1s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.2"}
                                                        >
                                                            0.2s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.3"}
                                                        >
                                                            0.3s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.4"}
                                                        >
                                                            0.4s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.5"}
                                                        >
                                                            0.5s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.6"}
                                                        >
                                                            0.6s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.8"}
                                                        >
                                                            0.8s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"1"}
                                                        >
                                                            1s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"1.2"}
                                                        >
                                                            1.2s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"1.4"}
                                                        >
                                                            1.4s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"1.8"}
                                                        >
                                                            1.8s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"2"}
                                                        >
                                                            2s
                                                        </SelectItemSidebar>
                                                    </SelectContentSidebar>
                                                </SelectSidebar>
                                            </div>
                                            <div className="flex flex-row items-center gap-4 w-full">
                                                <LabelSidebar className="w-[125px]">
                                                    Ease
                                                </LabelSidebar>
                                                <SelectSidebar
                                                    name="ease"
                                                    onValueChange={(
                                                        val:
                                                            | "ease-out-bounce"
                                                            | "ease-out"
                                                            | "ease-in"
                                                            | "linear"
                                                            | "ease-in-out"
                                                    ) => {
                                                        const _videoTracks = [
                                                            ...videoTracks,
                                                        ];
                                                        let _videoTrack =
                                                            _videoTracks[
                                                                videoTrackIndexSelected
                                                            ];

                                                        const indexOfAnimation: number =
                                                            _videoTrack.animations?.findIndex(
                                                                (_animation) =>
                                                                    _animation.animationKey ===
                                                                    animation.animationKey
                                                            ) || 0;

                                                        _videoTrack.animations![
                                                            indexOfAnimation
                                                        ].animationEase = val;

                                                        _videoTrack =
                                                            onAddAnimationToVideoItem(
                                                                videoSettings,
                                                                _videoTrack
                                                            );
                                                        setVideoTracks(
                                                            _videoTracks
                                                        );
                                                    }}
                                                    value={
                                                        animation.animationEase
                                                    }
                                                >
                                                    <SelectTriggerSidebar className="w-full h-8">
                                                        <SelectValueSidebar placeholder="Ease" />
                                                    </SelectTriggerSidebar>
                                                    <SelectContentSidebar>
                                                        <SelectItemSidebar
                                                            value={"ease-in"}
                                                        >
                                                            Ease in
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"linear"}
                                                        >
                                                            Linear
                                                        </SelectItemSidebar>
                                                    </SelectContentSidebar>
                                                </SelectSidebar>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
                <div className="flex items-start w-full flex-col gap-4 ">
                    <div className="flex flex-row w-full items-center justify-between text-xs text-zinc-200">
                        <button
                            className="flex py-2 w-full flex-row text-zinc-200 text-center hover:opacity-80"
                            onClick={() => {
                                setShowExitAnimations(!showExitAnimations);
                            }}
                        >
                            <span className="w-7 text-right flex justify-start pl-1">
                                {!showExitAnimations && (
                                    <ChevronRight className="w-3 h-3 text-zinc-400" />
                                )}
                                {showExitAnimations && (
                                    <ChevronDownIcon className="w-3 h-3 text-zinc-400" />
                                )}
                            </span>
                            <p className="text-xs ">
                                Exit Animations
                                <span className="bg-zinc-700 px-2 py-1 rounded-lg ml-2 font-semibold text-2xs">
                                    {
                                        videoTrack.animations?.filter(
                                            (animation) =>
                                                animation.animationAppend ===
                                                "lastFrame"
                                        ).length
                                    }
                                </span>
                            </p>
                        </button>
                        <Popover>
                            <PopoverTrigger
                                onClick={(e) => e.stopPropagation()}
                                className="w-[70px] shrink-0 py-1 text-xs text-right hover:opacity-80 font-semibold"
                            >
                                Add
                                <Plus className="w-3 ml-2 h-3 inline-block" />
                            </PopoverTrigger>
                            <PopoverContent>
                                <div className="flex flex-col gap-2">
                                    {ANIMATIONS.filter(
                                        (animation) =>
                                            animation.animationAppend ===
                                            "lastFrame"
                                    )
                                        .filter(
                                            (animation) =>
                                                findIfAnimationIsAlreadyAdded(
                                                    animation.animationValue
                                                ) === undefined
                                        )
                                        .map((animation, index) => (
                                            <button
                                                key={"popanex" + index}
                                                onClick={() =>
                                                    onAddAnimationToVideoTrackAnimations(
                                                        animation,
                                                        "LAST_FRAME"
                                                    )
                                                }
                                                className="flex flex-row items-center py-2 text-xs rounded-md text-zinc-200 px-2 hover:bg-zinc-800"
                                            >
                                                {animation.animationValue}
                                            </button>
                                        ))}
                                    <button></button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                    {showExitAnimations && (
                        <div className="pl-7 pb-8 flex flex-col gap-6 w-full">
                            {videoTrack.animations
                                ?.filter(
                                    (animation) =>
                                        animation.animationAppend ===
                                        "lastFrame"
                                )

                                .map((animation, index) => (
                                    <div
                                        className="w-full flex flex-col gap-2"
                                        key={
                                            animation.animationKey + "" + index
                                        }
                                    >
                                        <div className="flex flex-row justify-between items-center gap-4 w-full">
                                            <span className="text-white text-xs font-semibold">
                                                {animation.animationValue}
                                            </span>
                                            <button
                                                onClick={() =>
                                                    onRemoveAnimation(animation)
                                                }
                                            >
                                                <TrashIcon className="w-3 h-3 text-zinc-500 cursor-pointer hover:opacity-80 hover:text-red-500" />
                                            </button>
                                        </div>
                                        <div className="flex flex-col items-start gap-1 w-full">
                                            <div className="flex flex-row items-center gap-4 w-full">
                                                <LabelSidebar className="w-[125px]">
                                                    Duration
                                                </LabelSidebar>
                                                <SelectSidebar
                                                    name="duration"
                                                    onValueChange={(val) => {
                                                        onChangeDurationExit(
                                                            val,
                                                            animation
                                                        );
                                                    }}
                                                    value={
                                                        "" +
                                                        ((animation?.lastFrame ||
                                                            0) -
                                                            (animation?.firstFrame ||
                                                                0)) /
                                                            30
                                                    }
                                                >
                                                    <SelectTriggerSidebar className="w-full h-8">
                                                        <SelectValueSidebar placeholder="0.2s" />
                                                    </SelectTriggerSidebar>
                                                    <SelectContentSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.1"}
                                                        >
                                                            0.1s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.2"}
                                                        >
                                                            0.2s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.3"}
                                                        >
                                                            0.3s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.4"}
                                                        >
                                                            0.4s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.5"}
                                                        >
                                                            0.5s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.6"}
                                                        >
                                                            0.6s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"0.8"}
                                                        >
                                                            0.8s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"1"}
                                                        >
                                                            1s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"1.2"}
                                                        >
                                                            1.2s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"1.4"}
                                                        >
                                                            1.4s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"1.8"}
                                                        >
                                                            1.8s
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"2"}
                                                        >
                                                            2s
                                                        </SelectItemSidebar>
                                                    </SelectContentSidebar>
                                                </SelectSidebar>
                                            </div>
                                            <div className="flex flex-row items-center gap-4 w-full">
                                                <LabelSidebar className="w-[125px]">
                                                    Ease
                                                </LabelSidebar>
                                                <SelectSidebar
                                                    name="ease"
                                                    onValueChange={(
                                                        val:
                                                            | "ease-out-bounce"
                                                            | "ease-out"
                                                            | "ease-in"
                                                            | "linear"
                                                            | "ease-in-out"
                                                    ) => {
                                                        const _videoTracks = [
                                                            ...videoTracks,
                                                        ];
                                                        let _videoTrack =
                                                            _videoTracks[
                                                                videoTrackIndexSelected
                                                            ];
                                                        const indexOfAnimation: number =
                                                            _videoTrack.animations?.findIndex(
                                                                (_animation) =>
                                                                    _animation.animationKey ===
                                                                    animation.animationKey
                                                            ) || 0;

                                                        _videoTrack.animations![
                                                            indexOfAnimation
                                                        ].animationEase = val;

                                                        _videoTrack =
                                                            onAddAnimationToVideoItem(
                                                                videoSettings,
                                                                _videoTrack
                                                            );
                                                        setVideoTracks(
                                                            _videoTracks
                                                        );
                                                    }}
                                                    value={
                                                        animation.animationEase
                                                    }
                                                >
                                                    <SelectTriggerSidebar className="w-full h-8">
                                                        <SelectValueSidebar placeholder="Ease" />
                                                    </SelectTriggerSidebar>
                                                    <SelectContentSidebar>
                                                        <SelectItemSidebar
                                                            value={"ease-out"}
                                                        >
                                                            Ease out
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={
                                                                "ease-out-bounce"
                                                            }
                                                        >
                                                            Ease out bounce
                                                        </SelectItemSidebar>
                                                        <SelectItemSidebar
                                                            value={"linear"}
                                                        >
                                                            Linear
                                                        </SelectItemSidebar>
                                                    </SelectContentSidebar>
                                                </SelectSidebar>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TimelineAnimation;
