import {
    VideoItem,
    VideoItemAnimation,
    VideoSettings,
    VideoTrack,
} from "./models";
function cubicEaseIn(t: number) {
    return t * t * t * t * t;
}
function cubicEaseOut(t: number) {
    return 1 - Math.pow(1 - t, 5);
}
function cubicEaseLinear(t: number) {
    return t;
}
function easeOutBounce(x: number): number {
    const n1 = 7.5625;
    const d1 = 2.75;

    if (x < 1 / d1) {
        return n1 * x * x;
    } else if (x < 2 / d1) {
        return n1 * (x -= 1.5 / d1) * x + 0.75;
    } else if (x < 2.5 / d1) {
        return n1 * (x -= 2.25 / d1) * x + 0.9375;
    } else {
        return n1 * (x -= 2.625 / d1) * x + 0.984375;
    }
}
function easeInOut(x: number): number {
    return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
}
export function calculateEaseInterpolation(
    frame: number,
    startFrame: number,
    endFrame: number,
    startValue: number,
    endValue: number,
    animationEase: string
) {
    frame = Math.max(startFrame, Math.min(endFrame, frame));

    var progress = (frame - startFrame) / (endFrame - startFrame);

    var easedProgress = progress;
    if (animationEase === "ease-in") {
        easedProgress = cubicEaseIn(progress);
    }
    if (animationEase === "ease-out") {
        easedProgress = cubicEaseOut(progress);
    }
    if (animationEase === "linear") {
        easedProgress = cubicEaseLinear(progress);
    }
    if (animationEase === "ease-out-bounce") {
        easedProgress = easeOutBounce(progress);
    }
    if (animationEase === "ease-in-out") {
        easedProgress = easeInOut(progress);
    }

    var opacity = startValue + (endValue - startValue) * easedProgress;

    return opacity;
}

export function onAddAnimationToVideoItem(
    videoSettings: VideoSettings,
    videoTrack: VideoTrack,
    animations?: VideoItemAnimation[] | undefined
): VideoTrack {
    animations = animations
        ? animations
        : videoTrack.animations
        ? videoTrack.animations
        : [];
    const xPosition = videoTrack.placementHorizontal;
    const xPositionRelativeToCanvas = videoTrack.x || 0;
    const yPosition = videoTrack.placementVertical;
    const yPositionRelativeToCanvas = videoTrack.y || 0;

    const textBaseline =
        yPosition === "start"
            ? "top"
            : yPosition === "center"
            ? "middle"
            : "bottom";

    const textAlign =
        xPosition === "start"
            ? "left"
            : xPosition === "center"
            ? "center"
            : "right";

    videoTrack.items = []; //Clear single frames to start new with animation

    for (let i = videoTrack.firstFrame; i <= videoTrack.lastFrame; i++) {
        let videoTrackValue = videoTrack.value;
        let opacity = 1;
        let y = yPositionRelativeToCanvas;
        let x = xPositionRelativeToCanvas;
        let scale = 1;
        for (let b = 0; b < animations.length; b++) {
            const animation = animations[b];
            const firstFrame = animation.firstFrame
                ? animation.firstFrame
                : videoTrack.firstFrame;
            const lastFrame = animation.lastFrame
                ? animation.lastFrame
                : videoTrack.firstFrame +
                  Math.round(videoTrack.frameLength * 0.7);
            const firstValue = animation.firstValue ? animation.firstValue : 0;
            const lastValue = animation.lastValue ? animation.lastValue : 1;

            if (
                animation.animationKey === "opacity-fade-in-first-frame" &&
                i >= firstFrame
            ) {
                opacity = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    0,
                    1,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "slide-in-down-first-frame" &&
                i >= firstFrame
            ) {
                y = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    yPositionRelativeToCanvas - 50,
                    yPositionRelativeToCanvas,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "slide-in-up-first-frame" &&
                i >= firstFrame
            ) {
                y = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    yPositionRelativeToCanvas + 100,
                    yPositionRelativeToCanvas,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "slide-in-left-first-frame" &&
                i >= firstFrame
            ) {
                x = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    xPositionRelativeToCanvas - 100,
                    xPositionRelativeToCanvas,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "slide-in-right-first-frame" &&
                i >= firstFrame
            ) {
                x = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    xPositionRelativeToCanvas + 100,
                    xPositionRelativeToCanvas,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "scale-up-first-frame" &&
                i >= firstFrame
            ) {
                scale = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    0.6,
                    1,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "scale-down-first-frame" &&
                i >= firstFrame
            ) {
                scale = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    1.6,
                    1,
                    animation.animationEase
                );
            }
            //OUT
            if (
                animation.animationKey === "slide-out-up-last-frame" &&
                i >= firstFrame
            ) {
                y = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    yPositionRelativeToCanvas,
                    yPositionRelativeToCanvas - 100,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "slide-out-down-last-frame" &&
                i >= firstFrame
            ) {
                y = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    yPositionRelativeToCanvas,
                    yPositionRelativeToCanvas + 100,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "slide-out-right-last-frame" &&
                i >= firstFrame
            ) {
                x = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    xPositionRelativeToCanvas,
                    xPositionRelativeToCanvas + 100,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "slide-out-left-last-frame" &&
                i >= firstFrame
            ) {
                x = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    xPositionRelativeToCanvas,
                    xPositionRelativeToCanvas - 100,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "scale-up-last-frame" &&
                i >= firstFrame
            ) {
                scale = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    1,
                    1.6,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "scale-down-last-frame" &&
                i >= firstFrame
            ) {
                scale = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    1,
                    0.6,
                    animation.animationEase
                );
            }
            if (
                animation.animationKey === "opacity-fade-out-last-frame" &&
                i >= firstFrame
            ) {
                opacity = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    1,
                    0,
                    animation.animationEase
                );
            }
            if (animation.animationKey === "opacity" && i >= firstFrame) {
                opacity = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    firstValue,
                    lastValue,
                    animation.animationEase
                );
            }
            if (animation.animationKey === "x" && i >= firstFrame) {
                x = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    firstValue,
                    lastValue,
                    animation.animationEase
                );
            }
            if (animation.animationKey === "y" && i >= firstFrame) {
                y = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    firstValue,
                    lastValue,
                    animation.animationEase
                );
            }
            if (animation.animationKey === "scale" && i >= firstFrame) {
                scale = calculateEaseInterpolation(
                    i,
                    firstFrame,
                    lastFrame,
                    firstValue,
                    lastValue,
                    animation.animationEase
                );
            }
            //Effects
            if (animation.animationKey === "typewriter" && i >= firstFrame) {
                const charIndex = Math.round(
                    calculateEaseInterpolation(
                        i,
                        firstFrame,
                        lastFrame,
                        0,
                        videoTrackValue.length,
                        animation.animationEase
                    )
                );
                videoTrackValue = videoTrackValue.substring(0, charIndex);
            }
            if (animation.animationKey === "words-fade-in-first-frame" && i >= firstFrame) {
                const words = videoTrackValue.split(" ");
                const wordIndex = Math.round(
                    calculateEaseInterpolation(
                        i,
                        firstFrame,
                        lastFrame,
                        0,
                        words.length,
                        animation.animationEase
                    )
                );
                videoTrackValue = words.slice(0, wordIndex).join(" ");
            }
        }

        let videoItem: VideoItem = {
            name: videoTrack.name,
            value: videoTrackValue,
            fontFamily: videoTrack.fontFamily,
            color: videoTrack.color,
            fontSize: videoTrack.fontSize,
            fontColor: videoTrack.fontColor,
            backgroundColor: videoTrack.backgroundColor,
            textBaseline: textBaseline,
            textAlign: textAlign,
            x: x,
            y: y,
            opacity: opacity,
            height: videoTrack.height,
            width: videoTrack.width,
            frame: i,
            scale: scale,
        };

        videoTrack.items.push(videoItem);
    }

    videoTrack.x = xPositionRelativeToCanvas;
    videoTrack.y = yPositionRelativeToCanvas;

    return videoTrack;
}

export const onCreateVideoItemAnimation = (
    ANIMATION: VideoItemAnimation,
    videoTrack: VideoTrack
) => {
    const halfDuration =
        videoTrack.firstFrame +
        Math.round((videoTrack.lastFrame - videoTrack.firstFrame) / 2);

    const firstFrame =
        ANIMATION.animationAppend === "firstFrame"
            ? videoTrack.firstFrame
            : halfDuration;
    const lastFrame =
        ANIMATION.animationAppend === "firstFrame"
            ? halfDuration
            : videoTrack.lastFrame;
    const firstValue =
        ANIMATION.firstValue != undefined && ANIMATION.firstValue !== -1
            ? ANIMATION.firstValue
            : -1;
    const lastValue =
        ANIMATION.lastValue != undefined && ANIMATION.lastValue !== -1
            ? ANIMATION.lastValue
            : -1;
    const videoItemAnimation: VideoItemAnimation = {
        animationKey: ANIMATION.animationKey,
        animationValue: ANIMATION.animationValue,
        firstFrame: firstFrame,
        lastFrame: lastFrame,
        firstValue: firstValue,
        lastValue: lastValue,
        animationType: ANIMATION.animationType,
        animationEase: ANIMATION.animationEase,
    };
    return videoItemAnimation;
};

export function fillTextWithLineBreaksString(
    ctx: CanvasRenderingContext2D,
    text: string,
    maxWidth: number
) {
    const words = text.split(" ");
    let lines = [];
    let currentLine = words[0];
    for (let i = 1; i < words.length; i++) {
        const testLine = currentLine + " " + words[i];
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth) {
            lines.push(currentLine);
            currentLine = words[i];
        } else {
            currentLine = testLine;
        }
    }
    lines.push(currentLine);
    return lines.join("\n");
}

interface VideoTrackWidthAndHeight {
    width: number;
    height: number;
}

export async function calculcateWidthAndHeight(
    _videoTrack: VideoTrack,
    canvasRef: React.RefObject<HTMLCanvasElement>
): Promise<VideoTrackWidthAndHeight> {
    if (_videoTrack.name === "text") {
        const ctx = canvasRef.current!.getContext("2d")!;
        ctx.font = `${_videoTrack.fontWeight || "bold"} ${
            _videoTrack.fontSize
        }px ${_videoTrack.fontFamily}`;
        const textMetrics = ctx.measureText(_videoTrack.value);
        const textWidth = textMetrics.width;
        const textHeight =
            textMetrics.actualBoundingBoxAscent +
            textMetrics.actualBoundingBoxDescent;

        return {
            width: textWidth,
            height: textHeight,
        } as VideoTrackWidthAndHeight;
    } else if (_videoTrack.name === "image") {
        if (_videoTrack.imgCache) {
            return {
                width: _videoTrack.imgCache.width,
                height: _videoTrack.imgCache.height,
            } as VideoTrackWidthAndHeight;
        } else {
            const sizes = new Promise<VideoTrackWidthAndHeight>(
                (resolve, reject) => {
                    const img = new Image();

                    img.onload = function () {
                        resolve({
                            width: img.width,
                            height: img.height,
                        } as VideoTrackWidthAndHeight);
                    };
                    img.src = _videoTrack.value;
                }
            );
            const si = await sizes;
            return si;
        }
    } else {
        return { width: 0, height: 0 } as VideoTrackWidthAndHeight;
    }
}

export async function onUpdateWidthAndHeightOnVideoTracks(
    videoTracks: VideoTrack[],
    canvasRef: React.RefObject<HTMLCanvasElement>
) {
    for (let i = 0; i < videoTracks.length; i++) {
        const videoTrack = videoTracks[i];
        const sizes = await calculcateWidthAndHeight(videoTrack, canvasRef);
        videoTrack.width = sizes.width;
        videoTrack.height = sizes.height;
    }
    return videoTracks;
}

export const onPlaceHorizontal = (
    videoTrack: VideoTrack,
    xPosition: "center" | "start" | "end",
    videoSettings: VideoSettings
): number => {
    const xPositionRelativeToCanvas =
        xPosition === "start"
            ? 20
            : xPosition === "center"
            ? videoTrack.name === "image" && videoTrack.width
                ? Math.abs(videoTrack.width / 2 - videoSettings.width / 2)
                : videoSettings.width / 2
            : videoSettings.width - 100;
    return xPositionRelativeToCanvas;
};
export const onPlaceVertical = (
    videoTrack: VideoTrack,
    yPosition: "center" | "start" | "end",
    videoSettings: VideoSettings
): number => {
    const yPositionRelativeToCanvas =
        yPosition === "start"
            ? 20
            : yPosition === "center"
            ? videoTrack.name === "image" && videoTrack.height
                ? Math.abs(videoTrack.height / 2 - videoSettings.height / 2)
                : videoSettings.height / 2
            : videoSettings.height - 100;
    return yPositionRelativeToCanvas;
};
