import * as React from "react";

import { cn } from "../../../lib/utils";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    icon?: React.ReactNode;
    iconWord?: React.ReactNode;
}

const InputSidebar = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, iconWord, icon, ...props }, ref) => {
        return (
            <div className="relative">
                {icon && <div className="absolute left-3 top-3">{icon}</div>}
                {iconWord && (
                    <div className="absolute left-3  top-0 h-10 w-3  flex justify-center items-center">
                        {iconWord}
                    </div>
                )}
                <input
                    type={type}
                    className={cn(
                        "flex text-xs dark:text-zinc-50 h-10 w-full text-zinc-950 hover:bg-zinc-800/5 rounded-md border-zinc-200 bg-white px-3 py-2 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-900 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-zinc-800 dark:hover:bg-zinc-700 dark:bg-zinc-800/60 dark:ring-offset-zinc-800 dark:placeholder:text-zinc-500 dark:focus-visible:ring-zinc-800",
                        className,
                        icon || iconWord ? "pl-9" : ""
                    )}
                    ref={ref}
                    {...props}
                />
            </div>
        );
    }
);
InputSidebar.displayName = "InputSidebar";

export { InputSidebar };
