import { useState } from "react";
import { Button } from "../ui/button";
import { DialogContent, Dialog, DialogTitle } from "../ui/dialog";
import { Input } from "../ui/input";
import { InputFile } from "../ui/inputfile";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Textarea } from "../ui/textarea";
import { twMerge } from "tailwind-merge";
import {
    VideoItemAnimation,
    VideoSettings,
    VideoTrack,
} from "../../lib/models";
import getDummyData from "../../lib/dummy-data";
import {
    createVideoItemImage,
    createVideoItemText,
} from "../../lib/videoitemcreate";
import ANIMATIONS, { ANIMATION_TEMPLATES } from "../../lib/animations.constant";
import {
    onAddAnimationToVideoItem,
    onPlaceHorizontal,
    onPlaceVertical,
} from "../../lib/video-util";
import { usePostHog } from "posthog-js/react";

interface AIDialogProps {
    onClearVideoTracks: () => void;
    videoSettings: VideoSettings;
    setVideoSettings: (videoSettings: VideoSettings) => void;
    onSetTotalTimeFromVideoTracks: (videoTracks: VideoTrack[]) => number;
    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack: (
        videoTracks: VideoTrack[]
    ) => VideoTrack[];
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
    openAI: boolean;
    setOpenAI: (openAI: boolean) => void;
}

export const AIDialog = ({
    openAI,
    setOpenAI,
    onClearVideoTracks,
    videoSettings,
    setVideoSettings,
    onSetTotalTimeFromVideoTracks,
    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack,
    setVideoTracks,
}: AIDialogProps) => {
    const [aiLocalImages, setAILocalImages] = useState<any[]>([]);
    const [aiInputMsg, setInputMsg] = useState("");
    const [aiScrapedUrl, setAIScrapedUrl] = useState("");
    const [aiLoading, setAiLoading] = useState(false);
    const posthog = usePostHog();

    const onAIit = () => {
        onClearVideoTracks();
        setAiLoading(true);
        let msg = aiInputMsg;
        let existingMsg = "";

        posthog?.capture("on_ai_it", {
            message: aiInputMsg,
        });
        fetch(
            "https://europe-west1-contentai-371710.cloudfunctions.net/quickeditai",
            // "http://localhost:5555/",
            // process.env.REACT_APP_PUBLIC_AI_URL ||
            //     "https://europe-west1-contentai-371710.cloudfunctions.net/quickeditai",
            {
                method: "POST",
                body: JSON.stringify({
                    msg: msg,
                    videoDuration: videoSettings.duration,
                    videoFramerate: videoSettings.framerate,
                    videoHeight: videoSettings.height,
                    videoWidth: videoSettings.width,
                    existingMsg: existingMsg,
                    scrapeUrl: aiScrapedUrl,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((data): any => {
                console.log("AI", data);

                let newVideoTracks: VideoTrack[] = [];

                const message = data.msg.msg.split(" ");

                let animationTemplate = ANIMATION_TEMPLATES.find(
                    (e) => e.style === data.msg.style
                )!;

                if (!animationTemplate) {
                    animationTemplate = ANIMATION_TEMPLATES[0];
                }

                let animationTemplateIndex = 0;
                let countFrames = 0;

                message.forEach((item: any, index: number) => {
                    animationTemplateIndex =
                        index % animationTemplate.animations.length;

                    const isLastItem = index === message.length - 1;

                    const animations: VideoItemAnimation[] = [];

                    const firstAnimations =
                        animationTemplate.animations[animationTemplateIndex]
                            .firstFrames;
                    const lastAnimations =
                        animationTemplate.animations[animationTemplateIndex]
                            .lastFrames;

                    for (let i = 0; i < firstAnimations.length; i++) {
                        const firstAnimation = ANIMATIONS.find(
                            (e) => e.animationKey === firstAnimations[i]
                        );
                        if (firstAnimation !== undefined) {
                            animations.push(firstAnimation);
                        }
                    }

                    for (let i = 0; i < lastAnimations.length; i++) {
                        const lastAnimation = ANIMATIONS.find(
                            (e) => e.animationKey === firstAnimations[i]
                        );
                        if (lastAnimation !== undefined) {
                            animations.push(lastAnimation);
                        }
                    }

                    let videoTrack: VideoTrack = {
                        id: Math.random().toString(36).substr(2, 9),
                        videoChannelId: "channel1",
                        frameLength: isLastItem
                            ? videoSettings.framerate *
                              animationTemplate.animationDuration *
                              2
                            : videoSettings.framerate *
                              animationTemplate.animationDuration,
                        frameLengthDriven: true,
                        items: [],
                        animations: animations,
                        placementVertical: "center",
                        placementHorizontal: "center",
                        width: 300,
                        height: 300,
                        name: "text",
                        value: item.toString().replace(/['"]+/g, ""),
                        fontFamily: "Arial, sans-serif",
                        fontSize: 300,
                        fontColor: "#ffffff",
                        firstFrame: countFrames,
                        lastFrame: isLastItem
                            ? (countFrames +
                                  videoSettings.framerate *
                                      animationTemplate.animationDuration) *
                              2
                            : countFrames +
                              videoSettings.framerate *
                                  animationTemplate.animationDuration,
                    };
                    videoTrack.y = onPlaceVertical(
                        videoTrack,
                        "center",
                        videoSettings
                    );
                    videoTrack.x = onPlaceHorizontal(
                        videoTrack,
                        "center",
                        videoSettings
                    );
                    countFrames =
                        countFrames +
                        videoSettings.framerate *
                            animationTemplate.animationDuration;
                    videoTrack = onAddAnimationToVideoItem(
                        videoSettings,
                        videoTrack
                    );
                    newVideoTracks.push(videoTrack);
                });

                for (let ind = 0; ind < aiLocalImages.length; ind++) {
                    const element = aiLocalImages[ind];
                    let videoTrackImage: VideoTrack = createVideoItemImage({
                        value: element.base64,
                        frameLength:
                            videoSettings.framerate *
                            animationTemplate.animationDuration,
                        firstFrame: countFrames,
                        lastFrame:
                            countFrames +
                            videoSettings.framerate *
                                animationTemplate.animationDuration,
                        frameLengthDriven: true,
                        width: element.imageWidth,
                        height: element.imageHeight,
                    });
                    videoTrackImage.y = onPlaceVertical(
                        videoTrackImage,
                        "center",
                        videoSettings
                    );
                    videoTrackImage.x = onPlaceHorizontal(
                        videoTrackImage,
                        "center",
                        videoSettings
                    );
                    newVideoTracks.push(videoTrackImage);
                }

                setVideoSettings({
                    ...videoSettings,
                    duration:
                        newVideoTracks[newVideoTracks.length - 1].lastFrame /
                        videoSettings.framerate,
                });

                //Calc new duration based on items
                const aiDuration = Math.ceil(
                    onSetTotalTimeFromVideoTracks(newVideoTracks) /
                        videoSettings.framerate
                );

                //Check if data.msg.backgroundColor is a hex color
                const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
                const isHexColor = hexColorRegex.test(data.msg.backgroundColor);

                //Add bg
                newVideoTracks.push(
                    getDummyData(
                        videoSettings,
                        "background",
                        0,
                        aiDuration * videoSettings.framerate,
                        isHexColor ? data.msg.backgroundColor : "#23232D"
                    )
                );

                //Set correct firstFrame and lastFrame on all items
                newVideoTracks =
                    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack(
                        newVideoTracks
                    );

                //Maybe do a check here to see if the firstFrame and lastFrame all added together exceeds the limits
                setInputMsg("");
                setVideoSettings({
                    ...videoSettings,
                    duration: aiDuration,
                });
                setVideoTracks(newVideoTracks);
                setOpenAI(false);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const onScrapeIt = () => {
        onClearVideoTracks();
        setAiLoading(true);
        let msg = aiInputMsg;

        fetch(
            "https://europe-west1-contentai-371710.cloudfunctions.net/quickeditscraper",
            // "http://localhost:5555/",
            // "https://cf-api.tommyjepsen.workers.dev",
            // process.env.REACT_APP_PUBLIC_SCRAPE_URL ||
            //     "http://localhost:55555/",
            {
                method: "POST",
                body: JSON.stringify({
                    scrapeUrl: aiScrapedUrl,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("data.msg", data.msg);

                const splitData = data.msg.split(" ");
                console.log("splitData", splitData);

                let _videoTracksFromAI: VideoTrack[] = [];
                splitData.forEach((item: any) => {
                    const called: VideoTrack = createVideoItemText(
                        {
                            value: item,
                            fontSize: 260,
                            fontColor: "#ffffff",
                            frameLength: videoSettings.framerate / 2,
                        },
                        videoSettings
                    );
                    _videoTracksFromAI.push(called);
                });

                setVideoSettings({
                    ...videoSettings,
                    duration:
                        _videoTracksFromAI[_videoTracksFromAI.length - 1]
                            .lastFrame / videoSettings.framerate,
                });

                //Calc new duration based on items
                const aiDuration = Math.ceil(
                    onSetTotalTimeFromVideoTracks(_videoTracksFromAI) /
                        videoSettings.framerate
                );

                //Add bg
                _videoTracksFromAI.push(
                    getDummyData(
                        videoSettings,
                        "background",
                        0,
                        aiDuration * videoSettings.framerate,
                        "#131317"
                    )
                );

                //Set correct firstFrame and lastFrame on all items
                _videoTracksFromAI =
                    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack(
                        _videoTracksFromAI
                    );

                //Maybe do a check here to see if the firstFrame and lastFrame all added together exceeds the limits
                setInputMsg("");
                setVideoSettings({
                    ...videoSettings,
                    duration: aiDuration,
                });
                setVideoTracks(_videoTracksFromAI);
                setOpenAI(false);
                setAiLoading(false);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    return (
        <Dialog open={openAI} onOpenChange={() => setOpenAI(!openAI)}>
            <DialogContent className="lg:min-w-[500px] flex lg:gap-4 flex-col lg:flex-col">
                <DialogTitle className="text-white lg:max-w-[400px] font-semibold text-3xl lg:text-5xl tracking-tighter">
                    What do you feel like creating?
                </DialogTitle>
                <div className="flex h-full flex-row gap-4 w-full justify-between">
                    <Tabs defaultValue="prompting" className="w-full">
                        <TabsList className="mb-4 w-full lg:max-w-[100%]">
                            <TabsTrigger value="prompting" className="w-full">
                                Prompting
                            </TabsTrigger>
                            <TabsTrigger
                                value="website"
                                className="w-full flex flex-row gap-2"
                            >
                                Insert URL
                                <span className="inline-flex items-center rounded bg-rose-50 px-2 py-1/2 text-[10px] font-medium text-rose-500 ring-1 ring-inset ring-rose-500/10 uppercase">
                                    beta
                                </span>
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent
                            value="website"
                            className="h-[185px] flex justify-end items-end"
                        >
                            <div className="w-full gap-4 justify-start flex flex-col">
                                <p className="text-sm text-zinc-500 max-w-[400px]">
                                    This is an experimental feature, but feel
                                    free to give it a try. We're working hard on
                                    improving the results!
                                </p>
                                <div className="w-full gap-4 justify-start flex flex-rol">
                                    <Input
                                        classNameContainer="w-full flex-1"
                                        className="text-base"
                                        placeholder="https://"
                                        onChange={(e) => {
                                            setAIScrapedUrl(e.target.value);
                                        }}
                                    />
                                    <Button
                                        onClick={onScrapeIt}
                                        className={twMerge(
                                            "flex flex-row shrink-0 w-36",
                                            aiLoading ? "animate-pulse" : ""
                                        )}
                                        variant="default"
                                    >
                                        {aiLoading && (
                                            <div className="animate-pulse">
                                                ✨
                                            </div>
                                        )}
                                        {!aiLoading && <>Create magic ✨</>}
                                    </Button>
                                </div>
                            </div>
                        </TabsContent>
                        <TabsContent
                            value="prompting"
                            className="flex flex-col h-[185px] justify-end "
                        >
                            <Textarea
                                placeholder="A fast video saying we are hiring software engineers!"
                                className=" w-full text-base h-[125px] text-zinc-50 bg-zinc-900"
                                onChange={(e) => {
                                    setInputMsg(e.target.value);
                                }}
                                autoFocus
                            />
                            <div className="w-full mt-4 gap-4 justify-start flex flex-row">
                                <InputFile
                                    multiple={true}
                                    classNameContainer="w-full flex-1"
                                    onChange={async (e) => {
                                        const files = e.target.files!;
                                        const _aiLocalImages = [
                                            ...aiLocalImages,
                                        ];
                                        for (let i = 0; i < files.length; i++) {
                                            const file = files[i];
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                                const img = new Image();
                                                img.onload = function () {
                                                    _aiLocalImages.push({
                                                        base64: e.target!
                                                            .result as string,
                                                        name: file.name,
                                                        imageWidth: img.width,
                                                        imageHeight: img.height,
                                                    });
                                                };
                                                img.src = e.target!
                                                    .result as string;
                                            };
                                            reader.readAsDataURL(file);
                                        }
                                        setAILocalImages(_aiLocalImages);
                                    }}
                                />
                                <Button
                                    onClick={onAIit}
                                    className={twMerge(
                                        "flex flex-row shrink-0 w-36",
                                        aiLoading ? "animate-pulse" : ""
                                    )}
                                    variant="default"
                                >
                                    {aiLoading && (
                                        <div className="animate-pulse">✨</div>
                                    )}
                                    {!aiLoading && <>Create magic ✨</>}
                                </Button>
                            </div>
                        </TabsContent>
                    </Tabs>
                </div>
            </DialogContent>
        </Dialog>
    );
};
