import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

import { cn } from "../../../lib/utils";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

const SelectSidebar = SelectPrimitive.Root;

const SelectGroupSidebar = SelectPrimitive.Group;

const SelectValueSidebar = SelectPrimitive.Value;

interface SelectTriggerSidebarProps
    extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
    icon?: React.ReactNode;
}

const SelectTriggerSidebar = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    SelectTriggerSidebarProps
>(({ className, children, icon, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            `flex !bg-white dark:!bg-zinc-800 text-zinc-950 dark:text-zinc-50 hover:!bg-zinc-800/5 relative h-10 dark:hover:!bg-zinc-700
			 w-full items-center justify-between rounded-md border-zinc-200  px-3 py-2 text-xs
			  ring-offset-white placeholder:text-zinc-500 focus:outline-none focus:ring-2 
			  focus:ring-zinc-900 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50
			   dark:border-zinc-800 dark:ring-offset-zinc-900 dark:placeholder:text-zinc-400
			    dark:focus:ring-zinc-300`,
            className,
            icon ? "pl-9" : ""
        )}
        {...props}
    >
        {icon && <div className="absolute left-3 top-[14px]">{icon}</div>}
        {children}
        <SelectPrimitive.Icon asChild>
            <ChevronDownIcon className="h-4 w-4 opacity-50" />
        </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
));
SelectTriggerSidebar.displayName =
    SelectPrimitive.Trigger.displayName + "Sidebar";

const SelectContentSidebar = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cn(
                "relative z-50 min-w-[8rem] overflow-hidden rounded-md border-zinc-200 bg-white text-zinc-900 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-zinc-700 dark:bg-zinc-800 dark:text-zinc-50",
                position === "popper" &&
                    "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
                className
            )}
            position={position}
            {...props}
        >
            <SelectPrimitive.Viewport
                className={cn(
                    "p-1",
                    position === "popper" &&
                        "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
                )}
            >
                {children}
            </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
SelectContentSidebar.displayName =
    SelectPrimitive.Content.displayName + "Sidebar";

const SelectLabelSidebar = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn("py-1.5 pl-8 pr-2 text-sm font-semibold", className)}
        {...props}
    />
));
SelectLabelSidebar.displayName = SelectPrimitive.Label.displayName + "Sidebar";

const SelectItemSidebar = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            "relative text-xs hover:opacity-70 cursor-pointer flex w-full  select-none items-center rounded-sm py-1.5 pl-8 pr-2 outline-none focus:bg-white focus:text-zinc-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-zinc-800 dark:focus:text-zinc-50",
            className
        )}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <SelectPrimitive.ItemIndicator>
                <CheckIcon className="h-4 w-4" />
            </SelectPrimitive.ItemIndicator>
        </span>

        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
));
SelectItemSidebar.displayName = SelectPrimitive.Item.displayName + "Sidebar";

const SelectSeparatorSidebar = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator
        ref={ref}
        className={cn(
            "-mx-1 my-1 h-px bg-zinc-100 dark:bg-zinc-800",
            className
        )}
        {...props}
    />
));
SelectSeparatorSidebar.displayName =
    SelectPrimitive.Separator.displayName + "Sidebar";

export {
    SelectSidebar,
    SelectGroupSidebar,
    SelectValueSidebar,
    SelectTriggerSidebar,
    SelectContentSidebar,
    SelectLabelSidebar,
    SelectItemSidebar,
    SelectSeparatorSidebar,
};
