import {
    CanvasState,
    VideoItemAnimation,
    VideoSettings,
    VideoTrack,
} from "../../../lib/models";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { LabelSidebar } from "../../ui/sidebar/label";
import { InputSidebar } from "../../ui/sidebar/input";
import {
    onAddAnimationToVideoItem,
    onPlaceHorizontal,
    onPlaceVertical,
} from "../../../lib/video-util";
import SidebarHR from "../sidebar/SidebarHR";
import { GripVertical, TrashIcon } from "lucide-react";
import { useState } from "react";
import { InputFileSidebar } from "../../ui/sidebar/inputfile";
import TimelineAnimation from "./TimelineAnimation";

interface TimelineImageProps {
    videoTracks: VideoTrack[];
    videoTrack: VideoTrack;
    index: number;
    videoTrackIndexSelected: number;
    setVideoTrackIndexSelected: (index: number) => void;
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
    setVideoSettings: (videoSettings: VideoSettings) => void;
    canvasState: CanvasState;
    videoSettings: VideoSettings;
    setCanvasState: (canvasState: CanvasState) => void;
    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack: (
        videoTracks: VideoTrack[]
    ) => VideoTrack[];
    onSetVideoTrackFrameLengthToSec: (index: number, sec: number) => void;
}

export const TimelineImage = ({
    videoTracks,
    videoTrack,
    index,
    videoTrackIndexSelected,
    setVideoTrackIndexSelected,
    setVideoTracks,
    setVideoSettings,
    setCanvasState,
    canvasState,
    videoSettings,
    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack,
    onSetVideoTrackFrameLengthToSec,
}: TimelineImageProps) => {
    const [videoTrackAdvancedSettings, setVideoTrackAdvancedSettings] =
        useState<number>(-1);

    const getSecondFromFirstFrameAndMaxRunnableTimer = (firstFrame: number) => {
        return (
            (firstFrame / (videoSettings.duration * videoSettings.framerate)) *
            videoSettings.duration
        );
    };

    const findIfAnimationIsAlreadyAdded = (animation: VideoItemAnimation) => {
        return (
            videoTrack.animations?.find(
                (_animation) =>
                    _animation.animationValue === animation.animationValue
            ) !== undefined
        );
    };

    const onPlaceH = (xPosition: "center" | "start" | "end") => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];
        _videoTrack.x = onPlaceHorizontal(
            videoTracks[videoTrackIndexSelected],
            xPosition,
            videoSettings
        );
        _videoTrack.placementHorizontal = xPosition;
        _videoTrack = onAddAnimationToVideoItem(
            videoSettings,
            _videoTrack,
            _videoTrack.animations || []
        );
        setVideoTracks(_videoTracks);
    };
    const onPlaceV = (yPosition: "center" | "start" | "end") => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];
        _videoTrack.y = onPlaceVertical(
            videoTracks[videoTrackIndexSelected],
            yPosition,
            videoSettings
        );

        _videoTrack.placementVertical = yPosition;
        _videoTrack = onAddAnimationToVideoItem(
            videoSettings,
            _videoTrack,
            _videoTrack.animations || []
        );
        setVideoTracks(_videoTracks);
    };

    const [ratioLocked, setRatioLocked] = useState(true);

    const onChangeWidthOrHeightAndKeepRatio = (e: any, isWidth: boolean) => {
        const _videoTracks = [...videoTracks];
        const existingRatioFromHeightAndWidth =
            _videoTracks[videoTrackIndexSelected].height /
            _videoTracks[videoTrackIndexSelected].width;
        if (isWidth) {
            _videoTracks[videoTrackIndexSelected].width = +e.target.value;
            if (ratioLocked) {
                _videoTracks[videoTrackIndexSelected].height = Math.round(
                    (_videoTracks[videoTrackIndexSelected].width /
                        existingRatioFromHeightAndWidth) *
                        1
                );
            }
        } else {
            _videoTracks[videoTrackIndexSelected].height = +e.target.value;
            if (ratioLocked) {
                _videoTracks[videoTrackIndexSelected].width = Math.round(
                    _videoTracks[videoTrackIndexSelected].height *
                        existingRatioFromHeightAndWidth *
                        1
                );
            }
        }
        _videoTracks[videoTrackIndexSelected] = onAddAnimationToVideoItem(
            videoSettings,
            _videoTracks[videoTrackIndexSelected],
            _videoTracks[videoTrackIndexSelected].animations || []
        );
        setVideoTracks(_videoTracks);
    };

    return (
        <>
            <div className="flex flex-row justify-between">
                <div className="w-full text-base items-center flex flex-row gap-2">
                    <div className="w-[40px] flex justify-start items-center">
                        <Popover>
                            <PopoverTrigger
                                onClick={(e) => e.stopPropagation()}
                                className="w-[40px] shrink-0 py-1 text-zinc-400 text-xs  hover:opacity-80"
                            >
                                {`${getSecondFromFirstFrameAndMaxRunnableTimer(
                                    Math.abs(
                                        videoTrack.firstFrame -
                                            videoTrack.lastFrame
                                    )
                                ).toFixed(1)}s`}
                            </PopoverTrigger>
                            <PopoverContent>
                                <div className="flex mb-8 flex-col gap-2">
                                    <div className="text-sm w-full flex justify-between">
                                        <LabelSidebar>Start</LabelSidebar>
                                        {` ${getSecondFromFirstFrameAndMaxRunnableTimer(
                                            Math.abs(videoTrack.firstFrame)
                                        ).toFixed(1)}s `}
                                    </div>
                                    <div className="text-sm w-full flex justify-between">
                                        <LabelSidebar>End</LabelSidebar>
                                        {` ${getSecondFromFirstFrameAndMaxRunnableTimer(
                                            Math.abs(videoTrack.lastFrame)
                                        ).toFixed(1)}s`}
                                    </div>
                                </div>
                                <SidebarHR />
                                <div className="flex justify-between flex-row pb-8 py-4 items-center gap-2 justify-center">
                                    <LabelSidebar>Change length</LabelSidebar>
                                    <div className=" flex flex-row items-center">
                                        <InputSidebar
                                            className="w-full w-[60px]"
                                            value={getSecondFromFirstFrameAndMaxRunnableTimer(
                                                Math.abs(
                                                    videoTrack.firstFrame -
                                                        videoTrack.lastFrame
                                                )
                                            ).toFixed(1)}
                                            placeholder={getSecondFromFirstFrameAndMaxRunnableTimer(
                                                Math.abs(
                                                    videoTrack.firstFrame -
                                                        videoTrack.lastFrame
                                                )
                                            ).toFixed(1)}
                                            onChange={(e) => {
                                                console.log(
                                                    "Number(e.target.value)",
                                                    Number(e.target.value)
                                                );

                                                onSetVideoTrackFrameLengthToSec(
                                                    index,
                                                    Number(e.target.value)
                                                );
                                            }}
                                            min="0"
                                            step="0.1"
                                            type="number"
                                        />
                                        <span>s</span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-6 text-xs">
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.1
                                            )
                                        }
                                    >
                                        0.1s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.2
                                            )
                                        }
                                    >
                                        0.2s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.3
                                            )
                                        }
                                    >
                                        0.3s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.4
                                            )
                                        }
                                    >
                                        0.4s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.5
                                            )
                                        }
                                    >
                                        0.5s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.6
                                            )
                                        }
                                    >
                                        0.6s
                                    </button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                    {videoTrack.value !== "" ? (
                        <div className="w-full">
                            <img
                                src={videoTrack.value || ""}
                                className="h-5 rounded"
                                alt={videoTrack.value || ""}
                            />
                        </div>
                    ) : (
                        <div className="text-xs w-full text-zinc-500">
                            <InputFileSidebar
                                label="No image added"
                                onChange={(e) => {
                                    const file = e.target.files![0];
                                    console.log("file", file);
                                    const _videoTracks = [
                                        ...videoTracks,
                                    ] as VideoTrack[];
                                    _videoTracks[
                                        videoTrackIndexSelected
                                    ].value = e.target.value;
                                    if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (e) => {
                                            const img = new Image();
                                            img.onload = () => {
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ].value = e.target!
                                                    .result as string;
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ].width = img.width;
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ].height = img.height;
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ] = onAddAnimationToVideoItem(
                                                    videoSettings,
                                                    _videoTracks[
                                                        videoTrackIndexSelected
                                                    ],
                                                    _videoTracks[
                                                        videoTrackIndexSelected
                                                    ].animations || []
                                                );
                                                setVideoTracks(_videoTracks);
                                            };
                                            img.src = e.target!
                                                .result as string;
                                        };
                                        reader.readAsDataURL(file);
                                    }
                                }}
                            />
                        </div>
                    )}
                    <div className="p-1 cursor-move shrink w-[16px]">
                        <GripVertical className="w-3 text-zinc-600" />
                    </div>
                </div>
            </div>
            {videoTrackIndexSelected === index && (
                <div className="flex flex-col  mt-4 gap-2">
                    <TimelineAnimation
                        videoTracks={videoTracks}
                        videoTrack={videoTrack}
                        videoTrackIndexSelected={videoTrackIndexSelected}
                        setVideoTracks={setVideoTracks}
                        setVideoSettings={setVideoSettings}
                        videoSettings={videoSettings}
                    />
                </div>
            )}
        </>
    );
};
