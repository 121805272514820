import { twMerge } from "tailwind-merge";
import {
    CanvasState,
    VideoItemAnimation,
    VideoSettings,
    VideoTrack,
} from "../../../lib/models";
import { LeftSidebarCardClickable } from "../../ui/leftsidebar/leftsidebar-card-clickable";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import VideoTrackDot from "../VideoTrackDot";
import { LabelSidebar } from "../../ui/sidebar/label";
import { InputSidebar } from "../../ui/sidebar/input";
import { onAddAnimationToVideoItem } from "../../../lib/video-util";
import SidebarHR from "../sidebar/SidebarHR";
import { GripVertical, TrashIcon } from "lucide-react";
import { useState } from "react";
import TimelineAnimation from "./TimelineAnimation";

interface TimelineTextProps {
    videoTracks: VideoTrack[];
    videoTrack: VideoTrack;
    index: number;
    videoTrackIndexSelected: number;
    setVideoTrackIndexSelected: (index: number) => void;
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
    setVideoSettings: (videoSettings: VideoSettings) => void;
    canvasState: CanvasState;
    videoSettings: VideoSettings;
    setCanvasState: (canvasState: CanvasState) => void;
    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack: (
        videoTracks: VideoTrack[]
    ) => VideoTrack[];
    onSetVideoTrackFrameLengthToSec: (index: number, sec: number) => void;
}

export const TimelineText = ({
    videoTracks,
    videoTrack,
    index,
    videoTrackIndexSelected,
    setVideoTrackIndexSelected,
    setVideoTracks,
    setVideoSettings,
    setCanvasState,
    canvasState,
    videoSettings,
    onCalculateFirstAndLastFramesFromFrameLengthOnVideoTrack,
    onSetVideoTrackFrameLengthToSec,
}: TimelineTextProps) => {
    const [videoTrackAdvancedSettings, setVideoTrackAdvancedSettings] =
        useState<number>(-1);

    const getSecondFromFirstFrameAndMaxRunnableTimer = (firstFrame: number) => {
        return (
            (firstFrame / (videoSettings.duration * videoSettings.framerate)) *
            videoSettings.duration
        );
    };

    return (
        <>
            <div className="flex flex-row justify-between w-full gap-5">
                <div className="w-full text-base items-center justify-start flex flex-row gap-2">
                    <div className="w-[40px] flex justify-start items-center">
                        <Popover>
                            <PopoverTrigger
                                onClick={(e) => e.stopPropagation()}
                                className="w-[40px] shrink-0 py-1 text-zinc-400 text-xs  hover:opacity-80"
                            >
                                {`${getSecondFromFirstFrameAndMaxRunnableTimer(
                                    Math.abs(
                                        videoTrack.firstFrame -
                                            videoTrack.lastFrame
                                    )
                                ).toFixed(1)}s`}
                            </PopoverTrigger>
                            <PopoverContent>
                                <div className="flex mb-8 flex-row gap-2">
                                    <div className="text-base font-medium w-full flex flex-col justify-start">
                                        {` ${getSecondFromFirstFrameAndMaxRunnableTimer(
                                            Math.abs(videoTrack.firstFrame)
                                        ).toFixed(1)}s `}
                                        <LabelSidebar>Starts at</LabelSidebar>
                                    </div>
                                    <div className="text-base font-medium w-full flex flex-col justify-start">
                                        {` ${getSecondFromFirstFrameAndMaxRunnableTimer(
                                            Math.abs(videoTrack.lastFrame)
                                        ).toFixed(1)}s`}
                                        <LabelSidebar>Stops at</LabelSidebar>
                                    </div>
                                </div>
                                <SidebarHR />
                                <div className="flex text-sm text-zinc-400 flex-row pb-8 py-4 items-center gap-2 justify-center">
                                    <p>Change duration</p>
                                    <div className=" flex flex-row items-center">
                                        <InputSidebar
                                            className=" w-[70px]"
                                            value={getSecondFromFirstFrameAndMaxRunnableTimer(
                                                Math.abs(
                                                    videoTrack.firstFrame -
                                                        videoTrack.lastFrame
                                                )
                                            ).toFixed(1)}
                                            placeholder={getSecondFromFirstFrameAndMaxRunnableTimer(
                                                Math.abs(
                                                    videoTrack.firstFrame -
                                                        videoTrack.lastFrame
                                                )
                                            ).toFixed(1)}
                                            onChange={(e) => {
                                                onSetVideoTrackFrameLengthToSec(
                                                    index,
                                                    Number(e.target.value)
                                                );
                                            }}
                                            min="0"
                                            step="0.1"
                                            type="number"
                                        />
                                        <span className="ml-2">s</span>
                                    </div>
                                </div>
                                <LabelSidebar>
                                    Choose pre-selected duration
                                </LabelSidebar>

                                <div className="grid grid-cols-6 text-xs text-zinc-300 mt-2">
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.1
                                            )
                                        }
                                    >
                                        0.1s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.2
                                            )
                                        }
                                    >
                                        0.2s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.3
                                            )
                                        }
                                    >
                                        0.3s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.4
                                            )
                                        }
                                    >
                                        0.4s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.6
                                            )
                                        }
                                    >
                                        0.6s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                0.8
                                            )
                                        }
                                    >
                                        0.8s
                                    </button>
                                </div>
                                <div className="grid grid-cols-6 text-xs text-zinc-300 mt-2">
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                1.0
                                            )
                                        }
                                    >
                                        1s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                1.5
                                            )
                                        }
                                    >
                                        1.5s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                2.0
                                            )
                                        }
                                    >
                                        2s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                3.0
                                            )
                                        }
                                    >
                                        3s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                4.0
                                            )
                                        }
                                    >
                                        4s
                                    </button>
                                    <button
                                        className="px-3 py-2"
                                        onClick={() =>
                                            onSetVideoTrackFrameLengthToSec(
                                                index,
                                                5.0
                                            )
                                        }
                                    >
                                        5s
                                    </button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <input
                        type="text"
                        tabIndex={index}
                        className="w-full bg-zinc-900 px-2 text-sm h-8 outline-none active:bg-zinc-700 focus:bg-zinc-700 rounded-lg"
                        value={videoTrack.value}
                        autoFocus={videoTrackIndexSelected === index}
                        onChange={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            const _videoTracks = [...videoTracks];
                            _videoTracks[index].value = e.target.value;
                            _videoTracks[index] = onAddAnimationToVideoItem(
                                videoSettings,
                                _videoTracks[index],
                                _videoTracks[index].animations || []
                            );
                            setVideoTracks(_videoTracks);
                        }}
                    />
                    <div className="p-1 cursor-grab shrink w-[16px]">
                        <GripVertical className="w-3 text-zinc-600" />
                    </div>
                </div>
            </div>
            {videoTrackIndexSelected === index && (
                <div className="flex flex-col  mt-4 gap-2">
                    <TimelineAnimation
                        videoTracks={videoTracks}
                        videoTrack={videoTrack}
                        videoTrackIndexSelected={videoTrackIndexSelected}
                        setVideoTracks={setVideoTracks}
                        setVideoSettings={setVideoSettings}
                        videoSettings={videoSettings}
                    />
                </div>
            )}
        </>
    );
};
