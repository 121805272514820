// const FONTS = [
//     "Arial",
//     "Comic Sans MS",
//     "Courier New",
//     "Georgia",
//     "Helvetica",
//     "Lato",
//     "Lucida Grande",
//     "Montserrat",
//     "Open Sans",
//     "Oswald",
//     "PT Sans",
//     "Raleway",
//     "Roboto",
//     "Roboto Condensed",
//     "Source Sans Pro",
//     "Tahoma",
//     "Times New Roman",
//     "Trebuchet MS",
//     "Verdana",
// ];
const FONTS = [
    "Arial",
    "Courier New",
    "Georgia",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
    "Comic Sans MS",
    "Lucida Grande",
    "Tahoma",
    "Helvetica"
];

export default FONTS;
