import {
    VideoItemAnimation,
    VideoSettings,
    VideoTrack,
} from "../../../lib/models";
import { InputSidebar } from "../../ui/sidebar/input";
import {
    onAddAnimationToVideoItem,
    onPlaceHorizontal,
    onPlaceVertical,
} from "../../../lib/video-util";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import SidebarHR from "../sidebar/SidebarHR";
import { ButtonSidebar } from "../../ui/sidebar/button";
import { useState } from "react";
import IconWord from "../sidebar/IconWord";
import { InputFileSidebar } from "../../ui/sidebar/inputfile";
import { HeaderItemBackgroundColor } from "./HeaderItemBackgroundColor";

interface TimelineImageProps {
    videoTracks: VideoTrack[];
    videoTrackIndexSelected: number;
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
    videoSettings: VideoSettings;

    onSetVideoTrackFrameLengthToSec: (
        videoTrackIndexSelected: number,
        sec: number
    ) => void;
}

export const HeaderImage = ({
    videoTracks,
    videoTrackIndexSelected,
    setVideoTracks,
    videoSettings,
    onSetVideoTrackFrameLengthToSec,
}: TimelineImageProps) => {
    const [videoTrackAdvancedSettings, setVideoTrackAdvancedSettings] =
        useState<number>(-1);

    const getSecondFromFirstFrameAndMaxRunnableTimer = (firstFrame: number) => {
        return (
            (firstFrame / (videoSettings.duration * videoSettings.framerate)) *
            videoSettings.duration
        );
    };

    const findIfAnimationIsAlreadyAdded = (animation: VideoItemAnimation) => {
        return (
            videoTracks[videoTrackIndexSelected].animations?.find(
                (_animation) =>
                    _animation.animationValue === animation.animationValue
            ) !== undefined
        );
    };

    const onPlaceH = (xPosition: "center" | "start" | "end") => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];
        _videoTrack.x = onPlaceHorizontal(
            videoTracks[videoTrackIndexSelected],
            xPosition,
            videoSettings
        );
        _videoTrack.placementHorizontal = xPosition;
        _videoTrack = onAddAnimationToVideoItem(
            videoSettings,
            _videoTrack,
            _videoTrack.animations || []
        );
        setVideoTracks(_videoTracks);
    };
    const onPlaceV = (yPosition: "center" | "start" | "end") => {
        const _videoTracks = [...videoTracks];
        let _videoTrack = _videoTracks[videoTrackIndexSelected];
        _videoTrack.y = onPlaceVertical(
            videoTracks[videoTrackIndexSelected],
            yPosition,
            videoSettings
        );

        _videoTrack.placementVertical = yPosition;
        _videoTrack = onAddAnimationToVideoItem(
            videoSettings,
            _videoTrack,
            _videoTrack.animations || []
        );
        setVideoTracks(_videoTracks);
    };

    const [ratioLocked, setRatioLocked] = useState(true);

    const onChangeWidthOrHeightAndKeepRatio = (e: any, isWidth: boolean) => {
        const _videoTracks = [...videoTracks];
        const existingRatioFromHeightAndWidth =
            _videoTracks[videoTrackIndexSelected].height /
            _videoTracks[videoTrackIndexSelected].width;
        if (isWidth) {
            _videoTracks[videoTrackIndexSelected].width = +e.target.value;
            if (ratioLocked) {
                _videoTracks[videoTrackIndexSelected].height = Math.round(
                    (_videoTracks[videoTrackIndexSelected].width /
                        existingRatioFromHeightAndWidth) *
                        1
                );
            }
        } else {
            _videoTracks[videoTrackIndexSelected].height = +e.target.value;
            if (ratioLocked) {
                _videoTracks[videoTrackIndexSelected].width = Math.round(
                    _videoTracks[videoTrackIndexSelected].height *
                        existingRatioFromHeightAndWidth *
                        1
                );
            }
        }
        _videoTracks[videoTrackIndexSelected] = onAddAnimationToVideoItem(
            videoSettings,
            _videoTracks[videoTrackIndexSelected],
            _videoTracks[videoTrackIndexSelected].animations || []
        );
        setVideoTracks(_videoTracks);
    };

    return (
        <>
            <div className="grid grid-cols-1 gap-2">
                {/* <div className="flex flex-col gap-1">
                            <LabelSidebar>Link to image (https)</LabelSidebar>
                            <InputSidebar
                                name="inputValue"
                                value={
                                    videoTracks[videoTrackIndexSelected].value
                                }
                                onChange={(e) => {
                                    const _videoTracks = [...videoTracks];
                                    _videoTracks[
                                        videoTrackIndexSelected
                                    ].value = e.target.value;
                                    _videoTracks[videoTrackIndexSelected] =
                                        onAddAnimationToVideoItem(
                                            videoSettings,
                                            _videoTracks[
                                                videoTrackIndexSelected
                                            ],
                                            _videoTracks[
                                                videoTrackIndexSelected
                                            ].animations || []
                                        );
                                    setVideoTracks(_videoTracks);
                                }}
                            />
                        </div> */}
                <div className="flex flex-col gap-1">
                    <InputFileSidebar
                        label="Select image"
                        onChange={(e) => {
                            const file = e.target.files![0];
                            console.log("file", file);
                            const _videoTracks = [
                                ...videoTracks,
                            ] as VideoTrack[];
                            _videoTracks[videoTrackIndexSelected].value =
                                e.target.value;
                            if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    const img = new Image();
                                    img.onload = () => {
                                        _videoTracks[
                                            videoTrackIndexSelected
                                        ].value = e.target!.result as string;
                                        _videoTracks[
                                            videoTrackIndexSelected
                                        ].width = img.width;
                                        _videoTracks[
                                            videoTrackIndexSelected
                                        ].height = img.height;
                                        _videoTracks[videoTrackIndexSelected] =
                                            onAddAnimationToVideoItem(
                                                videoSettings,
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ],
                                                _videoTracks[
                                                    videoTrackIndexSelected
                                                ].animations || []
                                            );
                                        setVideoTracks(_videoTracks);
                                    };
                                    img.src = e.target!.result as string;
                                };
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                </div>
            </div>

            <SidebarHR />
            <div className="flex flex-row items-center justify-center gap-2">
                <div className="w-[150px]">
                    <InputSidebar
                        iconWord={<IconWord word="W" />}
                        name="inputWidth"
                        type="number"
                        value={videoTracks[videoTrackIndexSelected].width}
                        onChange={(e) => {
                            onChangeWidthOrHeightAndKeepRatio(e, true);
                        }}
                    />
                </div>
                <ButtonSidebar onClick={() => setRatioLocked(!ratioLocked)}>
                    {ratioLocked && (
                        <LockClosedIcon className="w-3 h-3 text-zinc-50" />
                    )}
                    {!ratioLocked && (
                        <LockOpenIcon className="w-3 h-3 text-zinc-50" />
                    )}
                </ButtonSidebar>
                <div className="w-[150px]">
                    <InputSidebar
                        iconWord={<IconWord word="H" />}
                        name="inputHeight"
                        type="number"
                        value={videoTracks[videoTrackIndexSelected].height}
                        onChange={(e) => {
                            onChangeWidthOrHeightAndKeepRatio(e, false);
                        }}
                    />
                </div>
                <HeaderItemBackgroundColor
                    videoTracks={videoTracks}
                    videoTrackIndexSelected={videoTrackIndexSelected}
                    setVideoTracks={setVideoTracks}
                />
            </div>
        </>
    );
};
