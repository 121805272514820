import { PaintBucketIcon } from "lucide-react";
import { VideoTrack } from "../../../lib/models";
import { InputSidebar } from "../../ui/sidebar/input";

interface HeaderItemBackgroundColorProps {
    videoTracks: VideoTrack[];
    videoTrackIndexSelected: number;
    setVideoTracks: (videoTracks: VideoTrack[]) => void;
}

export const HeaderItemBackgroundColor = ({
    videoTracks,
    videoTrackIndexSelected,
    setVideoTracks,
}: HeaderItemBackgroundColorProps) => {
    return (
        <div className="flex flex-row gap-1 items-center">
            <button
                onClick={() => {
                    const _videoTracks = [...videoTracks];
                    _videoTracks[videoTrackIndexSelected].backgroundColor = "";
                    _videoTracks[videoTrackIndexSelected].items.map(
                        (item: any) => {
                            item.backgroundColor = "";
                            return item;
                        }
                    );
                    setVideoTracks(_videoTracks);
                }}
            >
                <PaintBucketIcon className="w-4 h-4 text-zinc-400 mr-2" />
            </button>
            <InputSidebar
                type="color"
                name="inputBGFill"
                className="w-14"
                value={
                    videoTracks[videoTrackIndexSelected].backgroundColor ?? ""
                }
                onChange={(e) => {
                    const _videoTracks = [...videoTracks];
                    _videoTracks[videoTrackIndexSelected].backgroundColor =
                        e.target.value;
                    _videoTracks[videoTrackIndexSelected].items.map(
                        (item: any) => {
                            item.backgroundColor = e.target.value;
                            return item;
                        }
                    );
                    setVideoTracks(_videoTracks);
                }}
            />
            <div className="flex-1">
                <InputSidebar
                    type="text"
                    className="flex-1 w-20 shrink-0"
                    name="inputBGFill"
                    value={
                        videoTracks[videoTrackIndexSelected].backgroundColor ??
                        ""
                    }
                    onChange={(e) => {
                        const _videoTracks = [...videoTracks];
                        _videoTracks[videoTrackIndexSelected].backgroundColor =
                            e.target.value;
                        _videoTracks[videoTrackIndexSelected].items.map(
                            (item: any) => {
                                item.backgroundColor = e.target.value;
                                return item;
                            }
                        );
                        setVideoTracks(_videoTracks);
                    }}
                />
            </div>
        </div>
    );
};
