import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function hexToRGB(hex: string) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `${r}, ${g}, ${b}`;
}

export function RGBtoHex(r: number, g: number, b: number) {
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
}

export function secondsToTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0"); // Ensure two-digit minutes
    const formattedSeconds = String(remainingSeconds).padStart(2, "0"); // Ensure two-digit seconds

    return `${formattedMinutes}:${formattedSeconds}`;
}
const COLORS = [
    "#7cb814",
    "#5314b8",
    "#14b8a6",
    "#b8148c",
    "#1424b8",
    "#6366f1",
    "#ec4899",
    "#7114b8",
    "#14b8a6",
];
export const getColor = (str: string) => {
    //Get a random hex color from the str string
    // let hash = 0;
    // for (let i = 0; i < str.length; i++) {
    //     hash = str.charCodeAt(i) + ((hash << 5) - hash);
    // }
    // let color = "#";
    // for (let i = 0; i < 3; i++) {
    //     let value = (hash >> (i * 8)) & 0xff;
    //     color += ("00" + value.toString(16)).substr(-2);
    // }
    // return color + "5A";

    return COLORS[Math.floor(Math.random() * COLORS.length)];
};
